

import React, { useContext, useEffect, useRef, useState } from "react";
import { api } from "../../services/api";
import { ComponentContext } from "../../contexts/ComponentContext";
import { AuthContext } from "../../contexts/AuthContext";
import BadgePillFilter from "components/Filters/Badge";
import { useToasts } from "react-toast-notifications";
import { SubmitShopifyModalForm, ArchiveDeleteModalForm, DeleteModalForm, ProductStatus, ConfirmIndividualActionForm } from "../../components/Product"
import fileDownload from "js-file-download";
import moment from "moment";
import {
    Button,
    Card, CardFooter,
    CardHeader,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink,
    Row, Table,
    UncontrolledDropdown, UncontrolledTooltip, PopoverBody, PopoverHeader, UncontrolledPopover, Form,
    Spinner
} from "reactstrap";
import Filter from "../../components/Filters/Filter";
import Moment from "react-moment";
import AssigneeModalForm from "../../components/Modal/Assignee";
import { OrderStatus } from "../../components/Order";
import parse from "html-react-parser";
import { useHistory, useLocation } from "react-router-dom";
import BulkNotes from "components/Modal/bulkNotes";
import { addURLParam, processURLSearchParams, removeAllURLParams, removeURLParam } from "../../utils/urls";
import PagePagination from "feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import { Sort, handleSort } from "../../utils/commonFunctions";
import "../../utils/commonStyles.css";

const ProductListing = (props) => {
    const urlSearchParams = useLocation().search;

    const [Products, setProducts] = useState([])
    const [inventories, setInventories] = useState([])
    const [totalProducts, setTotalProducts] = useState(0)
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return []
        }
    });
    const [status, setStatus] = useState([])


    const [filter, setFilter] = useState({
        brands: [],
        product_status: [],
        distributors: [],
        distribution_states: [],
        users: [],
        productCategory: [],
        search: "",
        urlParamsProcessed: false,
    })

    const [sort, setSort] = useState("-id");
    const [sortOrder, setSortOrder] = useState({ order: "", columnName: "" });
    const [id, setId] = useState()
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState({
        submit_shopify: false,
        archive: false,
        delete: false,
        individual_archive: false,
        individual_delete: false,
        bulkNotes: false
    });

    const [selected, setSelected] = useState([])
    const [params, setParams] = useState([])
    const [clearall, setClearAll] = useState(false)
    const { component } = useContext(ComponentContext)
    const { addToast } = useToasts();
    const [notesLoading, setNotesLoading] = useState(false);

    // Check weather screen size is mobile or desktop
    const isMobile = useMobileDetection(mobileScreenThresholdValue);

    const getParams = () => {
        let new_params = {
            page,
            ordering: sort
        }

        if (filter.search?.length > 0) {
            new_params.search = filter.search
            setClearAll(true)
        }

        if (filter.brands.length > 0) {
            new_params.brand = []
            filter.brands.map(item => new_params.brand.push(item.value))
            setClearAll(true)
        }

        if (filter.distribution_states.length > 0) {
            new_params.state = [];
            filter.distribution_states.map((item) => new_params.state.push(item.value));
            setClearAll(true);
        }

        if (filter.users.length > 0) {
            new_params.userFilter = [];
            filter.users.map((item) => new_params.userFilter.push(item.value));
            setClearAll(true);
        }

        if (filter.product_status.length > 0) {
            new_params.product_status = []
            filter.product_status.map(item => new_params.product_status.push(item.value))
            setClearAll(true)
        }

        if (filter.distributors.length > 0) {
            new_params.distributors = [];
            filter.distributors.map(item => new_params.distributors.push(item.value));
            setClearAll(true);
        }

        // 7195951443 - Product Page - Filter & Publish To M&G Issue
        if (filter.productCategory?.length > 0) {
            new_params.productCategory = [];
            filter.productCategory.map(item => new_params.productCategory.push(item.value));
            setClearAll(true);
        }

        setParams(new_params)
        return new_params;
    }

    useEffect(() => {

        api.get('/product/product-status/')
            .then(response => {
                setStatus(response.data.results)
            })
            .catch(error => console.log(error))

    }, [])

    const history = useHistory();

    React.useMemo(() => {
        processURLSearchParams(new URLSearchParams(urlSearchParams), filter, setFilter);
    }, [urlSearchParams]);


    useEffect(() => {
        if (filter.urlParamsProcessed) {
            setLoading(true);

            const new_params = getParams();

            api.get('/product/list/', {
                params: new_params
            })
                .then(response => {

                    setProducts(response.data.results);
                    setInventories(response.data.inventories)
                    setTotalProducts(response.data.count);

                    const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
                    const previous = (page !== 1) ? page - 1 : 1
                    const next = (page !== totalPages) ? page + 1 : totalPages
                    setPagination({
                        previous,
                        next,
                        pages: () => {

                            let startCount = 1;
                            let endCount = isMobile ? 4 : 12;
                            let numberCount = Math.round(endCount / 2)
                            const pageList = [];
                            if (numberCount < 0) numberCount = 1;
                            startCount = page - numberCount

                            // If start count is negative or equal to zero
                            if (startCount <= 0) startCount = 1;
                            if (page !== 1) endCount = page + numberCount;
                            if (endCount > totalPages) endCount = totalPages;

                            if (totalPages >= endCount) {
                                for (let i = startCount; i <= endCount; i++) {
                                    pageList.push(i)
                                }
                            } else if (totalPages >= 1) {
                                for (let i = 1; i <= totalPages; i++) {
                                    pageList.push(i)
                                }
                            }
                            return pageList;
                        }
                    })
                    setLoading(false);
                    delete new_params['page']
                    delete new_params['ordering']
                    setParams(new_params);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }, [page, sort, filter, refresh, component])

    const handleClick = (e) => {
        return true
    }

    const handlePagination = page => {
        setPage(page)
    }

    const refProductsCheckbox = useRef(false)

    const handleProductsCheckbox = (e) => {

        refProductsCheckbox.current = e.target.checked
        let checkboxes = document.querySelectorAll('.productCheckbox');

        let items = [];
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = e.target.checked;
            if (e.target.checked === true) {
                items.push({ id: parseInt(checkboxes[i].value), title: checkboxes[i].name })
            }
        }
        setSelected(items)

    }

    const [exportButton, setExportButton] = useState({
        label: "Export",
        disabled: false,
    });

    /**
     * Export for Products
     */
    const handleExportProducts = () => {
        setExportButton({
            label: "Exporting...",
            disabled: true,
        });

        let new_params = params;
        new_params.product_ids = selected.map((item) => item.id);

        api
            .get("product/export/", {
                params: new_params,
            })
            .then((response) => response.data)
            .then((response) => {
                const success = response?.success | false;

                if (success) {
                    addToast(response.message, {
                        appearance: "success",
                        autoDismiss: false,
                    });
                } else {
                    const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
                    const filename = "products_" + timestamp + ".csv";
                    fileDownload(response, filename);
                }

                setExportButton({
                    label: "Export",
                    disabled: false,
                });
            });
    };


    const handleCheckbox = (e) => {
        let checkbox = [...new Set(selected)]
        const productRecord = Products.find(product => product.id == e.target.value);

        if (e.target.checked) {
            checkbox.push({ id: e.target.value, title: e.target.name, note: productRecord.internal_note })
        } else {
            // !== strict inequality operator checking the order id available or not, so that's why we use != inequality operator
            checkbox = checkbox.filter(item => item.id != e.target.value)
        }

        setSelected(checkbox)

    }

    const onFilterChange = (e, prefix) => {
        setPage(1);        // 7195951443 - Product Page - Filter & Publish To M&G Issue
        let filteredRecords = [...new Set(filter[`${prefix}`])];

        if (e.target.checked) {
            filteredRecords.push({ value: e.target.value, label: e.target.dataset.label });
            addURLParam(window, prefix, e.target.value);
        } else {
            filteredRecords = filteredRecords.filter(item => item.value !== e.target.value);
            removeURLParam(window, prefix, e.target.value);
        }
        let updatedFilter = { ...filter };
        updatedFilter[`${prefix}`] = filteredRecords;
        setFilter(updatedFilter);
    }

    const handleStates = (e) => {
        setPage(1);        // 7195951443 - Product Page - Filter & Publish To M&G Issue
        if (e == "__all__") {
            let distribution_states = [];

            let checkbox = document.querySelectorAll(".checkbox_state");
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                distribution_states.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label,
                });
                addURLParam(window, "distribution_states", checkbox[i].value);
            }
            setFilter({ ...filter, distribution_states: distribution_states });
        } else {
            let checkbox = [...new Set(filter.distribution_states)];

            if (e.target.checked) {
                checkbox.push({ value: e.target.value, label: e.target.dataset.label });
                addURLParam(window, "distribution_states", e.target.value);
            } else {
                checkbox = checkbox.filter((item) => item.value !== e.target.value);
                removeURLParam(window, "distribution_states", e.target.value);
            }

            setFilter({ ...filter, distribution_states: checkbox });
        }
    };

    const handleUserFilter = (e) => {
        setPage(1);        // 7195951443 - Product Page - Filter & Publish To M&G Issue
        let checkbox = [...new Set(filter.users)];

        if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
            addURLParam(window, "users", e.target.value);
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
            removeURLParam(window, "users", e.target.value);
        }
        setFilter({ ...filter, users: checkbox });
    };

    const handleProductCategory = (e) => {
        setPage(1);        // 7195951443 - Product Page - Filter & Publish To M&G Issue
        let checkbox = [...new Set(filter.productCategory)];

        if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
            addURLParam(window, "productCategory", e.target.value);
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
            removeURLParam(window, "productCategory", e.target.value);
        }
        setFilter({ ...filter, productCategory: checkbox });
    };
    const handleProductStatus = (e) => {
        setPage(1);        // 7195951443 - Product Page - Filter & Publish To M&G Issue
        if (e == "__all__") {
            let product_status = [];
            let checkbox = document.querySelectorAll(".checkbox_status");
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                product_status.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label,
                });
                addURLParam(window, "product_status", checkbox[i].value);
            }
            setFilter({ ...filter, product_status: product_status });
        } else {
            let checkbox = [...new Set(filter.product_status)]
            if (e.target.checked) {
                checkbox.push({ value: e.target.value, label: e.target.dataset.label });
                addURLParam(window, "product_status", e.target.value);
            } else {
                checkbox = checkbox.filter(item => item.value !== e.target.value);
                removeURLParam(window, "product_status", e.target.value);
            }
            setFilter({ ...filter, product_status: checkbox });
        };
    };

    const handleProducts = (e) => {
        let checkbox = [...new Set(filter.products)]

        if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label })
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value)
        }

        setFilter({ ...filter, products: checkbox })
    }

    const handleRefresh = () => {
        setRefresh(!refresh)
    }

    const clearProductsSelected = () => {
        setSelected([])
        let checkboxes = document.querySelectorAll('.productCheckbox');

        let items = [];
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false;
        }
    }

    let timer = null;
    const handleSearch = (e) => {
        setPage(1);        // 7195951443 - Product Page - Filter & Publish To M&G Issue
        addURLParam(window, "search", e.target.value);
        clearTimeout(timer);
        timer = setTimeout(function () {
            setFilter({
                ...filter,
                search: e.target.value
            })
        }, 750);
    }

    const clearFilter = (prefix) => {

        if (prefix === "brands") {
            setFilter({
                ...filter, brands: []
            })
            removeURLParam(window, "brands");
        } else if (prefix === "product_status") {
            setFilter({
                ...filter, product_status: []
            })
            removeURLParam(window, "product_status");
        } else if (prefix === "distributors") {
            setFilter({
                ...filter, distributors: []
            })
            removeURLParam(window, "distributors");
        } else if (prefix === "distribution_states") {
            setFilter({
                ...filter, distribution_states: []
            })
            removeURLParam(window, "distribution_states");
        } else if (prefix === "userFilter") {
            setFilter({
                ...filter, users: []
            })
            removeURLParam(window, "users");
        }
        else if (prefix === "productCategory") {
            setFilter({
                ...filter, productCategory: []
            })
            removeURLParam(window, "productCategory");
        }
        setClearAll(false)
        setPage(1);        // 7195951443 - Product Page - Filter & Publish To M&G Issue
    }

    const removeBadge = (e) => {

        if (e.component === 'brand') {
            let items = filter.brands
            items = items.filter(item => item.value !== e.item.value)
            setFilter({ ...filter, brands: items })
            removeURLParam(window, "brands", e.item.value);
        } else if (e.component === "product") {
            let items = filter.products
            items = items.filter(item => item.value !== e.item.value)
            setFilter({ ...filter, products: items })
            removeURLParam(window, "product", e.item.value);
        } else if (e.component === 'search') {
            setFilter({ ...filter, search: null })
            removeURLParam(window, "search");
            document.getElementById("productPageSeachInput").value = "";
        } else if (e.component == 'selected') {
            let items = selected
            items = items.filter(item => item.id !== e.item.id)
            setSelected(items)
            let checkbox = document.getElementById('checkbox_' + e.item.id)
            if (checkbox) {
                checkbox.checked = false
            }
        }
        else if (e.component == 'product_status') {
            let items = filter.product_status
            items = items.filter(item => item.value !== e.item.value)
            setFilter({ ...filter, product_status: items })
            removeURLParam(window, "product_status", e.item.value);
        } else if (e.component == 'distributors') {
            let items = filter.distributors
            items = items.filter(item => item.value !== e.item.value)
            setFilter({ ...filter, distributors: items })
            removeURLParam(window, "distributors", e.item.value);
        } else if (e.component === "distribution_states") {
            let items = filter.distribution_states;
            items = items.filter((item) => item.value !== e.item.value);
            setFilter({ ...filter, distribution_states: items });
            removeURLParam(window, "distribution_states", e.item.value);
        }
        else if (e.component === "userFilter") {
            let items = filter.users;
            items = items.filter((item) => item.value !== e.item.value);
            setFilter({ ...filter, users: items });
            removeURLParam(window, "users", e.item.value);
        }
        else if (e.component === "productCategory") {
            let items = filter.productCategory;
            items = items.filter((item) => item.value !== e.item.value);
            setFilter({ ...filter, productCategory: items });
            removeURLParam(window, "productCategory", e.item.value);
        }
        setClearAll(false)
        setPage(1);        // 7195951443 - Product Page - Filter & Publish To M&G Issue

    }

    const handleClearAll = () => {
        setPage(1);        // 7195951443 - Product Page - Filter & Publish To M&G Issue

        setClearAll(false)

        document.getElementById("productPageSeachInput").value = "";
        setFilter({
            brands: [],
            product_status: [],
            distributors: [],
            distribution_states: [],
            users: [],
            search: '',
            urlParamsProcessed: true,
        })
        removeAllURLParams()
    }

    const exitSubmitShopifyModal = () => {
        setOpenModal({
            assignee: false
        })
    }

    const exitArchiveModal = () => {
        setOpenModal({
            orderStatus: false
        })
    }

    const handlerArchiveIndiv = (e) => {
        setId(e)
        setOpenModal({ individual_archive: true })
    }

    const handlerDeleteIndiv = (e) => {
        setId(e)
        setOpenModal({ individual_delete: true })
    }

    const exitDeleteModal = () => {
        setOpenModal({
            orderStatus: false
        })
    }

    const handleBulkNotes = () => {
        setOpenModal({ bulkNotes: true });
    }

    const exitBulkNotesModal = () => {
        setOpenModal({
            bulkNotes: false
        });
    };

    const { user, isAuthentication } = useContext(AuthContext)

    if (isAuthentication && !(user.isProductFeature)) {
        window.location.href = window.env.APP_URL;
    }

    const inputNotes = useRef();

    const handleInternalNotes = (e, orderId, internalNote, handleRefresh) => {
        e.preventDefault();

        let notes = internalNote;
        let id = orderId;
        let idArray = [];
        if (Array.isArray(orderId)) {
            // If Parameter is an array assign it to idArray
            idArray = orderId
        } else if (Number.isInteger(orderId)) {
            // If Parameter is an integer push it to idArray
            idArray.push(orderId);
        }

        if (inputNotes.current) {
            [notes, id] = inputNotes.current;
        }

        setNotesLoading(true);

        const data = {
            notes: notes,
            products: idArray,
        };
        api
            .put("product/internal-notes/", data)
            .then((response) => {
                setNotesLoading(false);
                let notes_products = Products.map((item) => {
                    // 6011065090 - Prevent page reload after adding notes
                    if (Number.isInteger(id)) {
                        if (item.id === id) {
                            item.internal_note = notes;
                        }
                    }
                    else if (Array.isArray(id)) {
                        if (id.includes(item.id)) {
                            item.internal_note = notes;
                        }
                    }
                    return item;
                });

                // 6011065090 - Prevent page reload after adding notes
                // if (handleRefresh) {
                //     handleRefresh();
                //     setSelected([])
                // }

                setProducts(notes_products);
                addToast("Saved Successfully", {
                    appearance: "success",
                    autoDismiss: true,
                });
                exitBulkNotesModal();
                inputNotes.current = null;

                //Close notes popover
                document.body.click();
            })
            .catch((error) => {
                setNotesLoading(false);
                addToast(error.message, { appearance: "error" });
            });
    }

    const workflowTooltips = {
        user: 'Product will be reviewed soon.',
        published: 'Product has been published.',
        shopify: 'Product is under review.',
    };
    return (
        <>
            {(isAuthentication && user.isProductFeature) ?
                <Container fluid>
                    <SubmitShopifyModalForm
                        isOpen={openModal.submit_shopify}
                        products={selected}
                        onExit={exitSubmitShopifyModal}
                        title={'Submit'}
                        url={'product/submit/shopify/'}
                        approval_url={'/product/submit/shopify/approval/'}
                    />

                    <ArchiveDeleteModalForm
                        isOpen={openModal.archive}
                        products={selected}
                        id={id}
                        onExit={exitSubmitShopifyModal}
                        title={'Archive'}
                        filter={filter}
                        onClick={clearProductsSelected}
                        url={'product/archive/'}
                    />
                    {openModal?.bulkNotes && selected.length > 0 &&
                        <BulkNotes
                            isOpen={openModal.bulkNotes}
                            onExit={exitBulkNotesModal}
                            selected={selected}
                            handleInternalNotes={handleInternalNotes}
                            notesLoading={notesLoading}
                            inputNotes={inputNotes}
                            handleRefresh={handleRefresh}
                        />
                    }
                    <DeleteModalForm
                        isOpen={openModal.delete}
                        products={selected}
                        onExit={exitSubmitShopifyModal}
                        title={'Delete'}
                        onClick={clearProductsSelected}
                        url={'product/delete/'}
                    />
                    <ConfirmIndividualActionForm
                        isOpen={openModal.individual_archive}
                        product={id}
                        onExit={exitSubmitShopifyModal}
                        title={'Archive'}
                        onClick={clearProductsSelected}
                        url={'product/archive/'}
                    />

                    <ConfirmIndividualActionForm
                        isOpen={openModal.individual_delete}
                        product={id}
                        onExit={exitSubmitShopifyModal}
                        title={'Delete'}
                        onClick={clearProductsSelected}
                        url={'product/delete/'}
                    />

                    <div className={"pt-7"}>
                        {isAuthentication && <Row className={"mb-2"}>
                            <Col xs={10} lg={"2"} className={"text-left"}>
                                <input type="text"
                                    className={"button-font-size form-control form-control-sm"}
                                    placeholder={"Product Name or SKU or Variant ID"}
                                    defaultValue={filter.search}
                                    onChange={handleSearch}
                                    newparams={""}
                                    id="productPageSeachInput"
                                />
                            </Col>
                            <Col xs={2} lg={"10"} className={"text-right"}>
                                <div className={"d-none d-lg-inline-block mr-2"}>
                                    {/* 7278215305 - increase font size */}
                                    <button className="button-font-size btn btn-neutral btn-sm p-9px" onClick={handleRefresh}>
                                        <i className="fas fa-sync-alt"></i>
                                    </button>


                                    {/*    <Filter name={"Product"}*/}
                                    {/*            prefix={"product"}*/}
                                    {/*            onChange={handleProducts}*/}
                                    {/*            newparams={params}*/}
                                    {/*            selected={filter.products}*/}
                                    {/*            url={"/product/filter/variant/"}*/}
                                    {/*            onClear={() => clearFilter("product")}*/}
                                    {/*    />*/}

                                    <Filter name={"Distributors"}
                                        prefix={"distributors"}
                                        onChange={onFilterChange}
                                        newparams={params}
                                        selected={filter.distributors}
                                        url={"/product/filter/distributor/"}
                                        onClear={() => clearFilter("distributors")}
                                    />

                                    <Filter name={"Brand"}
                                        prefix={"brands"}
                                        onChange={onFilterChange}
                                        newparams={params}
                                        selected={filter.brands}
                                        url={"/product/filter/brand/"}
                                        onClear={() => clearFilter("brands")}
                                    />
                                    <Filter name={"Status"}
                                        prefix={"status"}
                                        onChange={handleProductStatus}
                                        newparams={params}
                                        selected={filter.product_status}
                                        url={"/product/product-status/"}
                                        onClear={() => clearFilter("product_status")}
                                    />
                                    <Filter
                                        name={"Distribution State"}
                                        prefix={"state"}
                                        onChange={handleStates}
                                        newparams={params}
                                        selected={filter.distribution_states}
                                        url={"product/filter/distributionstate/"}
                                        inputSearch={true}
                                        onClear={() => clearFilter("distribution_states")}
                                    />
                                    <Filter
                                        name={"User"}
                                        prefix={"userFilter"}
                                        onChange={handleUserFilter}
                                        newparams={params}
                                        selected={filter.users}
                                        url={"product/filter/user/"}
                                        inputSearch={true}
                                        onClear={() => clearFilter("userFilter")}
                                    />
                                    <Filter
                                        name={"Product Category"}
                                        prefix={"productCategory"}
                                        onChange={handleProductCategory}
                                        newparams={params}
                                        selected={filter.productCategory}
                                        url={"order/filter/product-category/"}
                                        inputSearch={true}
                                        onClear={() => clearFilter("productCategory")}
                                    />
                                    {/* 7278215305 - increase font size */}
                                    <Button
                                        color={"primary"}
                                        size={"sm"}
                                        className="button-font-size p-10px"
                                        disabled={exportButton.disabled}
                                        onClick={handleExportProducts}
                                    >
                                        <i className="fas fa-file-csv"></i> {exportButton.label}
                                    </Button>
                                </div>
                            </Col>

                        </Row>}
                        <Row>
                            {isAuthentication && <div className="col">
                                <Card className="shadow ">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <Col>
                                                <h3 className="mb-0">Product <small>({totalProducts})</small>{clearall &&
                                                    <a href={void (0)}
                                                        className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                                                        onClick={handleClearAll}
                                                        id="clear_all_filters">
                                                        <i className="fas fa-times-circle"></i> Clear All filters
                                                    </a>
                                                }</h3>

                                            </Col>
                                            <Col className="text-right">
                                                {(selected.length > 0 && user.isMaster) && <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className="btn text-primary"
                                                        href="#"
                                                        role="button"
                                                        size="sm"
                                                        color="white"
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        <i className="fas fa-cogs" /> Actions
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                        <DropdownItem
                                                            href="#"
                                                            onClick={(e) => setOpenModal({ submit_shopify: !openModal.submit_shopify })}

                                                        >
                                                            <i className="fa fas fa-share fa-fw"></i>Publish to Mash&Grape
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            href="#"
                                                            onClick={(e) => setOpenModal({ archive: !openModal.archive })}

                                                        >
                                                            <i className="fas fa-archive"></i>
                                                            Archive
                                                        </DropdownItem>
                                                        <DropdownItem href="#" onClick={(e) => setOpenModal({ delete: !openModal.delete })}>
                                                            <i className="fas fa-trash fa-fw text-red"></i>
                                                            <span className={'text-red'}>Delete</span>
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            href="#"
                                                            onClick={(e) => handleBulkNotes(selected)}
                                                        >
                                                            <i className="fa fa-sticky-note" /> Add Bulk Notes
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>}
                                                {/* 7278215305 - increase font size */}
                                                {!user.isRetailer &&
                                                    <a href={'create-product'} className="button-font-size btn btn-success btn-sm">
                                                        <i className="fas fa-plus"></i> Add
                                                    </a>
                                                }
                                            </Col>

                                            <Col sm="12"><BadgePillFilter title="Product" data={filter}
                                                selected={selected} onClick={removeBadge} />
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    {isLoading ?
                                        <div className="text-center mt-3 mb-3">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div> :
                                        <>
                                            <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col" className="pl-3 pr-3">
                                                            <input
                                                                type="checkbox"
                                                                name={"check_orders"}
                                                                id={"checkbox_orders"}
                                                                ref={refProductsCheckbox}
                                                                defaultChecked={refProductsCheckbox.current}
                                                                onChange={handleProductsCheckbox} />
                                                        </th>

                                                        {(user.isAdmin || user.isRetailer) && (
                                                            <th scope="col" className="pl-1 pr-1">
                                                                Notes
                                                            </th>
                                                        )}
                                                        <th scope="col" className="pl-3 pr-1">
                                                            <Sort onClick={() => handleSort(sort, "name", setSortOrder, setSort)}
                                                                sortingOrder={sortOrder}
                                                                column="name">Name
                                                            </Sort>
                                                        </th>
                                                        <th scope="col" className="pl-3 pr-3"><Sort
                                                            onClick={() => handleSort(sort, "brand__name", setSortOrder, setSort)} sortingOrder={sortOrder} column="brand__name">Brand</Sort></th>
                                                        <th scope="col" className="pl-3 pr-3"><Sort
                                                            onClick={() => handleSort(sort, "product_type", setSortOrder, setSort)} sortingOrder={sortOrder} column="product_type">Product Type</Sort></th>
                                                        <th scope="col" className="pl-3 pr-3"><Sort
                                                            onClick={() => handleSort(sort, "product_status", setSortOrder, setSort)} sortingOrder={sortOrder} column="product_status">Product Status</Sort></th>

                                                        <th scope="col" className="pl-3 pr-3"><Sort
                                                            onClick={() => handleSort(sort, "created_at", setSortOrder, setSort)} sortingOrder={sortOrder} column="created_at">Created At</Sort></th>

                                                        <th scope="col" className="pl-3 pr-3"><Sort
                                                            onClick={() => handleSort(sort, "updated_at", setSortOrder, setSort)} sortingOrder={sortOrder} column="updated_at">Updated At</Sort></th>

                                                        <th scope="col" className="pl-3 pr-3">Owner</th>
                                                        <th scope="col" className="pl-3 pr-3"></th>
                                                        <th scope="col" className="pl-3 pr-3"></th>



                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Products.map(product => (
                                                        <tr key={product.id}>
                                                            <td className={"pr-3 pl-3"}>
                                                                <input type="checkbox"
                                                                    name={product.name}
                                                                    value={product.id}
                                                                    id={"checkbox_" + product.id}
                                                                    className={"productCheckbox"}
                                                                    onChange={(e) => handleCheckbox(e)}
                                                                    defaultChecked={product?.checked} />
                                                            </td>
                                                            {(user.isAdmin || user.isRetailer) && (
                                                                <td className="pl-1 pr-1">
                                                                    {product.internal_note ? (
                                                                        <>
                                                                            <i
                                                                                className={"fa fa-sticky-note text-primary"}
                                                                                style={{ cursor: "pointer" }}
                                                                                id={"popover_" + product.id}
                                                                            ></i>
                                                                            <UncontrolledTooltip
                                                                                delay={0}
                                                                                placement="top"
                                                                                target={"popover_" + product.id}
                                                                            >
                                                                                {parse(product.internal_note)}
                                                                            </UncontrolledTooltip>
                                                                        </>
                                                                    ) : (
                                                                        <i
                                                                            className={"fa fa-sticky-note"}
                                                                            style={{ cursor: "pointer" }}
                                                                            id={"popover_" + product.id}
                                                                        ></i>
                                                                    )}
                                                                    {user.isAdmin && (
                                                                        <UncontrolledPopover
                                                                            placement="bottom"
                                                                            width={"300px"}
                                                                            target={"popover_" + product.id}
                                                                            trigger="legacy"
                                                                        >
                                                                            <PopoverHeader>
                                                                                Internal Notes
                                                                            </PopoverHeader>
                                                                            <PopoverBody className={""}>
                                                                                <Form
                                                                                    onSubmit={(e) =>
                                                                                        handleInternalNotes(
                                                                                            e,
                                                                                            product.id,
                                                                                            product.internal_note
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <textarea
                                                                                        name="inote"
                                                                                        onChange={(e) =>
                                                                                        (inputNotes.current = [
                                                                                            e.target.value,
                                                                                            product.id,
                                                                                        ])
                                                                                        }
                                                                                        rows={10}
                                                                                        style={{ width: "100%" }}
                                                                                        defaultValue={product.internal_note}
                                                                                    ></textarea>
                                                                                    <Button color={"success"} disabled={notesLoading}>
                                                                                        Add note
                                                                                        {notesLoading && <Spinner size="sm" color="light" />}
                                                                                    </Button>
                                                                                </Form>
                                                                            </PopoverBody>
                                                                        </UncontrolledPopover>
                                                                    )}
                                                                </td>
                                                            )}
                                                            <td className="pl-3 pr-1">
                                                                {/* <span style={{ color: "#5e72e4", cursor: "pointer" }} onClick={
                                                                    () => {
                                                                        const url = `/product-details?${new URLSearchParams({ productId: `${product.id}` })}`;;
                                                                        window.open(url, '_blank');

                                                                    }

                                                                } > */}
                                                                {/* {product.name.length > 50 ? product.name.substring(0, 50) + '...' :
                                                                        product.name} */}
                                                                {/* </span> */}
                                                                <a href={`/product-details?${new URLSearchParams({ productId: `${product.id}` })}`}>
                                                                    {product.name.length > 50 ? product.name.substring(0, 50) + '...' :
                                                                        product.name}
                                                                </a>

                                                            </td>
                                                            <td className="pl-3 pr-3">{product.brand_name}</td>
                                                            <td className="pl-3 pr-3">{product.product_type}</td>
                                                            <td className="pl-1 pr-1">
                                                                <ProductStatus data={status}
                                                                    product={product.id}>{product.product_status}</ProductStatus>
                                                            </td>
                                                            <td className="pl-3 pr-3"><Moment format="MM/D/YYYY"
                                                                withTitle>{product.created_at}</Moment></td>
                                                            <td className="pl-3 pr-3"><Moment format="MM/D/YYYY"
                                                                withTitle>{product.updated_at}</Moment></td>
                                                            <td className="pl-3 pr-3">
                                                                <a href={
                                                                    `mailto:${product.owner_email}?subject=Your product ${product.name}`
                                                                }
                                                                    target="_blank"
                                                                >
                                                                    {product.owner_name}
                                                                </a>
                                                            </td>
                                                            <td className="pl-3 pr-3">
                                                                {product.workflow && (
                                                                    <>
                                                                        <span>
                                                                            <i id={product.workflow} className={product.workflow_icon_class}></i>
                                                                        </span>
                                                                        <UncontrolledTooltip delay={0} placement="bottom" target={product.workflow}>
                                                                            {workflowTooltips[product.workflow]}
                                                                        </UncontrolledTooltip>
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td className="text-right">
                                                                {user.isAdmin &&
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="btn text-primary"
                                                                            href="#"
                                                                            role="button"
                                                                            size="sm"
                                                                            color="white"
                                                                            onClick={(e) => e.preventDefault()}
                                                                        >
                                                                            <i className="fas fa-ellipsis-v text-dark"></i>
                                                                        </DropdownToggle>


                                                                        <DropdownMenu className="dropdown-menu-arrow" modifiers={{ preventOverflow: { boundariesElement: 'window' } }} positionFixed={true} rightx>
                                                                            <DropdownItem
                                                                                href={window.env.APP_URL + "product/" + product.id + '/submit-for-approval/'}
                                                                                target="_blank"
                                                                                disabled={!(product.workflow=="user")}        // 7195951443 - Product Page - Filter & Publish To M&G Issue
                                                                            >
                                                                                <i className="fa fa-share"></i>Publish to Mash&Grape
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                href="#"
                                                                                onClick={(e) => handlerArchiveIndiv(product)}
                                                                            >
                                                                                <i className="fas fa-archive"></i>
                                                                                Archive
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                href="#"
                                                                                onClick={(e) => handlerDeleteIndiv(product)}
                                                                            >
                                                                                <i className="fas fa-trash fa-fw text-red"></i>
                                                                                <span className={'text-red'}>Delete</span>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            <PagePagination pagination={pagination} handlePagination={handlePagination} page={page} totalCount={totalProducts} />
                                        </>
                                    }
                                </Card>
                            </div>}
                        </Row>
                    </div>
                </Container> :
                null
            }
        </>
    );


}

export default ProductListing