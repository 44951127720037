import React, { useContext, useEffect, useRef, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Moment from "react-moment";
import { api } from "services/api";
import { AuthContext } from "contexts/AuthContext";
import Filter from "../../components/Filters/Filter";
import BadgePillFilter from "components/Filters/Badge";
import { ProductsSearchForm } from "../../components/Retailer/OverrideRetailerModal";
import { EditOverrideRetailerSearchForm } from "../../components/Retailer/UpdateOverrideRetailerModal";
import UpdateRetailerAssignmentLocationModal from "../../components/Retailer/UpdateRetailerAssignmentLocationModal";
import fileDownload from "js-file-download";
import moment from "moment";
import PagePagination from "feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import { Sort, handleSort } from "../../utils/commonFunctions";

const RetailerOverride = (props) => {
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    previous: null,
    next: null,
    pages: () => {
      return [];
    },
  });

  const { addToast } = useToasts();
  const [sort, setSort] = useState("-id");
  const [sortOrder, setSortOrder] = useState({order: "", columnName: ""});
  const [isLoading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState();
  const [openEditModal, setOpenEditModal] = useState();
  const [
    deleteRetailerOverrideProductName,
    setdeleteRetailerOverrideProductName,
  ] = useState();
  const [
    deleteRetailerOverrideInventoryLocation,
    setdeleteRetailerOverrideInventoryLocation,
  ] = useState();
  const [deleteRetailerOverrideSKU, setdeleteRetailerOverrideSKU] = useState();
  const [deleteRetailerOverrideState, setdeleteRetailerOverrideState] =
    useState();
  const [deleteRetailerOverrideId, setdeleteRetailerOverrideId] = useState();

  const [
    updateRetailerOverrideInventoryLocation,
    setupdateRetailerOverrideInventoryLocation,
  ] = useState();
  const [updateRetailerOverrideVariant, setupdateRetailerOverrideVariant] =
    useState();
  const [updateRetailerOverrideState, setupdateRetailerOverrideState] =
    useState();
  const [updateRetailerOverrideChannel, setupdateRetailerOverrideChannel] =
    useState();
  const [updateRetailerOverrideChannelId, setupdateRetailerOverrideChannelId] =
    useState();
  const [updateRetailerOverrideId, setupdateRetailerOverrideId] = useState();
  const [updateRetailerOverrideSku, setupdateRetailerOverrideSku] = useState();
  // @TODO: Update the variable name
  const [
    delete_retailer_override_assignee_list,
    setdelete_retailer_override_assignee_list,
  ] = useState([]);
  const [isActiveButton, setisActiveButton] = useState(false);

  const [status, setStatus] = useState([]);
  const [selected, setSelected] = useState([]);
  const [params, setParams] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [clearall, setClearAll] = useState(false);
  const [openUpdateLocationModal, setOpenUpdateLocationModal] = useState();
  const [retailerOverrideList, setRetailerOverrideList] = useState([]);
  const [overrideAssigneeId, setOverrideAssigneeId] = useState([]);
  const [selectAllRetailerOverridesInThisView, setselectAllRetailerOverridesInThisView] = useState(false);
  const [filter, setFilter] = useState({
    search: null,
    brands: [],
    products: [],
    states: [],
    inventory_location: [],
    sku: [],
  });

  
  // Check weather screen size is mobile or desktop
  const isMobile = useMobileDetection(mobileScreenThresholdValue);

  const refOrdersCheckbox = useRef(false);

  /**
   * gets called when select all checkbox on table is selected
   * @param {event} e 
   */
  const handleOrdersCheckbox = (e) => {
    // Show/Hide action button based on checkbox selection
    setisActiveButton(e.target.checked);

    refOrdersCheckbox.current = e.target.checked;
    let checkboxes = document.querySelectorAll(".orderCheckbox");

    let items = [];

    // If select all checkbox is selected then select all checkboxes and vice-versa
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = e.target.checked;
    }

    // If checkbox is selected then set orders data in setdelete_retailer_override_assignee_list
    if (e.target.checked) {
      items = orders
    }

    setdelete_retailer_override_assignee_list(items);
    setRetailerOverrideList(items);
  };

  const handleCheckbox = (e) => {
    let checkbox = [...new Set(selected)];

    if (e.target.checked) {
      checkbox.push({ id: e.target.value, title: e.target.name });
    } else {
      // !== strict inequality operator checking the order id available or not, so that's why we use != inequality operator
      checkbox = checkbox.filter((item) => item.id != e.target.value);
    }

    setSelected(checkbox);
  };

  const getParams = () => {
    let new_params = {
      page,
      ordering: sort,
    };
    if (filter.search?.length > 0) {
      new_params.search = filter.search;
      setClearAll(true);
    }

    if (filter.brands.length > 0) {
      new_params.retailer_brand_ids = [];
      filter.brands.map((item) => new_params.retailer_brand_ids.push(item.value));
      setClearAll(true);
    }
    if (filter.states.length > 0) {
      new_params.state = [];
      filter.states.map((item) => new_params.state.push(item.value));
      setClearAll(true);
    }
    if (filter?.inventory_location.length > 0) {
      new_params.inventory_location = [];
      filter.inventory_location.map((item) =>
        new_params.inventory_location.push(item.value)
      );
      setClearAll(true);
    }
    if (filter.products.length > 0) {
      new_params.retailer_product_ids = [];
      filter.products.map((item) => new_params.retailer_product_ids.push(item.value));
      setClearAll(true);
    }
    if (filter.sku?.length > 0) {
      new_params.sku = [];
      filter.sku.map((item) => new_params.sku.push(item.value));
      setClearAll(true);
    }
    setClearAll(
      !!(filter.search?.length || filter.brands.length || filter.states.length || filter?.inventory_location.length || filter.products.length || filter.sku?.length)
    );    
    setParams(new_params);
    return new_params;
  };
  useEffect(() => {
    api
      .get("retailer-override-ui/")
      .then((response) => {
        setStatus(response.data.results);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    setLoading(true);

    const new_params = getParams();
    api
      .get("retailer-override-ui/", {
        params: new_params,
      })
      .then((response) => {
        const list = response.data.results.map((order) => {
          const s = selected.filter((item) => parseInt(item.id) === order.id);
          order.checked = s.length > 0;
          return order;
        });
        setOrders(list);
        setTotalOrders(response.data.count);

        const totalPages = Math.ceil(
          response.data.count / window.env.PAGE_SIZE
        );
        const previous = page !== 1 ? page - 1 : 1;
        const next = page !== totalPages ? page + 1 : totalPages;
        setPagination({
          previous,
          next,
          pages: () => {
            let startCount = 1;
            let endCount = isMobile ? 4 : 12;
            let numberCount = Math.round(endCount / 2);
            const pageList = [];
            if (numberCount < 0) {
              numberCount = 1;
            }

            startCount = page - numberCount;

            if (startCount <= 0) {
              startCount = 1;
            }
            if (page !== 1) {
              endCount = page + numberCount;
            }
            if (endCount > totalPages) endCount = totalPages;

            if (totalPages >= endCount) {
              for (let i = startCount; i <= endCount; i++) {
                pageList.push(i);
              }
            } else if (totalPages >= 1) {
              for (let i = 1; i <= totalPages; i++) {
                pageList.push(i);
              }
            }

            return pageList;
          },
        });
        setLoading(false);
        delete new_params["page"];
        delete new_params["ordering"];
        setParams(new_params);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, sort, filter, refresh]);

  const handlePagination = (page) => {
    setPage(page);
  };

  const { user, isAuthentication } = useContext(AuthContext);

  let timer = null;

  const removeBadge = (e) => {
    setPage(1);
    if (e.component === "search") {
      setFilter({ ...filter, search: null });
    }
    else if (e.component === "state") {
      let items = filter.states;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, states: items });
    }
    else if (e.component === "brand") {
      let items = filter.brands;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, brands: items });
    }
    else if (e.component === "state") {
      let items = filter.state;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, state: items });
    }
    else if (e.component === "product") {
      let items = filter.products;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, products: items });
    }
    else if (e.component === "inventory_location") {
      let items = filter.inventory_location;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, inventory_location: items });
    } else if (e.component === "sku") {
      let items = filter.sku;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, sku: items });
    } else if (e.component === "selected") {
      let items = selected;
      items = items.filter((item) => item.id !== e.item.id);
      setSelected(items);
      let checkbox = document.getElementById("checkbox_" + e.item.id);
      if (checkbox) {
        checkbox.checked = false;
      }
    }
    setClearAll(false);
  };

  const handleBrands = (e) => {
    setPage(1);
    if (e === "__all__") {
      let status = [];

      let checkbox = document.querySelectorAll(".checkbox_brand");
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = true;
        status.push({
          value: checkbox[i].value,
          label: checkbox[i].dataset.label,
        });
      }
      setFilter({ ...filter, brands: status });

    } else {
    let checkbox = [...new Set(filter.brands)];

    if (e.target.checked) {
      checkbox.push({ value: e.target.value, label: e.target.dataset.label });
    } else {
      checkbox = checkbox.filter((item) => item.value !== e.target.value);
    }

    setFilter({ ...filter, brands: checkbox });
  }
  };

  const handleStates = (e) => {
    setPage(1);
    if (e == "__all__") {
      let status = [];

      let checkbox = document.querySelectorAll(".checkbox_state");
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = true;
        status.push({
          value: checkbox[i].value,
          label: checkbox[i].dataset.label,
        });
      }
      setFilter({ ...filter, states: status });
    } else {
      let checkbox = [...new Set(filter.states)];

      if (e.target.checked) {
        checkbox.push({ value: e.target.value, label: e.target.dataset.label });
      } else {
        checkbox = checkbox.filter((item) => item.value !== e.target.value);
      }

      setFilter({ ...filter, states: checkbox });
    }
  };
  
  const handleInventoryLocation = (e) => {
    setPage(1);
    let checkbox = [...new Set(filter.inventory_location)];

    if (e.target.checked) {
      checkbox.push({ value: e.target.value, label: e.target.dataset.label });
    } else {
      checkbox = checkbox.filter((item) => item.value !== e.target.value);
    }

    setFilter({ ...filter, inventory_location: checkbox });
  };

  const handleProducts = (e) => {
    setPage(1);
    let checkbox = [...new Set(filter.products)];

    if (e.target.checked) {
      checkbox.push({ value: e.target.value, label: e.target.dataset.label });
    } else {
      checkbox = checkbox.filter((item) => item.value !== e.target.value);
    }

    setFilter({ ...filter, products: checkbox });
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const clearFilter = (prefix) => {
    setPage(1);
    if (prefix === "brand") {
      setFilter({
        ...filter,
        brands: [],
      });
    }
    else if (prefix === "product") {
      setFilter({
        ...filter,
        products: [],
      });
    }
    else if (prefix === "state") {
      setFilter({
        ...filter,
        states: [],
      });
    }
    else if (prefix === "inventory_location") {
      setFilter({
        ...filter,
        inventory_location: [],
      });
    } else if (prefix === "sku") {
      setFilter({
        ...filter,
        sku: [],
      });
    }

    let checkbox = document.querySelectorAll(
      ".checkbox_" + prefix + ":checked"
    );
    if (checkbox.length > 0) {
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = false;
      }
    }
    setClearAll(false);
  };

  const handleOverrideAssigneeCheckbox = (
    e,
    retailer_override_assignee,
    product_name,
    sku,
    inventory_location,
    state,
    variant,
  ) => {
    var new_retailer_override_assignee_list = [];
    var retailer_instance = {
      id: retailer_override_assignee,
      product_name: product_name,
      sku: sku,
      inventory_location: inventory_location,
      state: state,
      variant: variant,
    };
    if (delete_retailer_override_assignee_list.length <= 1) {
      setisActiveButton(false);
    }
    if (delete_retailer_override_assignee_list.length > 0) {
      const index = delete_retailer_override_assignee_list.findIndex(
        (element) => element.id === retailer_override_assignee
      );
      if (index !== -1) {
        delete_retailer_override_assignee_list.splice(index, 1);
      } else {
        new_retailer_override_assignee_list =
          delete_retailer_override_assignee_list;
        new_retailer_override_assignee_list.push(retailer_instance);
        setdelete_retailer_override_assignee_list(
          new_retailer_override_assignee_list
        );
        setRetailerOverrideList(new_retailer_override_assignee_list);
        setisActiveButton(true);
      }
    } else {
      new_retailer_override_assignee_list =
        delete_retailer_override_assignee_list;
      new_retailer_override_assignee_list.push(retailer_instance);
      setdelete_retailer_override_assignee_list(
        new_retailer_override_assignee_list
      );
      setRetailerOverrideList(new_retailer_override_assignee_list);
      setisActiveButton(true);
      /*
        Jira: BCD-1584
        Description: Export retailers list to a CSV file
        Added by: ABHIJITH WARRIER
      */
      let retailer_override_assignee = [...new Set(overrideAssigneeId)];
      setOverrideAssigneeId(retailer_override_assignee);
    }
  };

  const handleClearAll = () => {
    setPage(1);
    setClearAll(false);
    setRetailerOverrideList([]);
    setSelected([]);
    setFilter({
      brands: [],
      products: [],
      states: [],
      inventory_location: [],
    });
  };

  const toggle = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const DeleteRetailerOverride = (override_assignee_id) => {
    let deleted_override_assignee_id = "";
    if (typeof override_assignee_id === "string") {
      deleted_override_assignee_id = override_assignee_id;
    }
    if (typeof override_assignee_id === "object") {
      let override_assignee_id_list = [];
      override_assignee_id.forEach((event) => {
        override_assignee_id_list.push(event.id);
      });
      deleted_override_assignee_id = override_assignee_id_list;
    }
    let deleteOrderOverridePageParams = getParams(filter);
    /**
    * Jira: BCD-4024 
    * Description: Added Bulk (Select All in this view functionality) 
    * delete records functionality
    */
    const params = {
      "select_all_retailer_overrides_in_this_view" : selectAllRetailerOverridesInThisView,
      "retailer_override_count_on_ui" : totalOrders,
      "inventory_location" : deleteOrderOverridePageParams?.inventory_location ?? null,
      "state" : deleteOrderOverridePageParams?.state ?? null,
      "brand" : deleteOrderOverridePageParams?.retailer_brand_ids ?? null,
      "product" : deleteOrderOverridePageParams?.retailer_product_ids ?? null
    }
    
    api
      .delete(
        `retailer-override-ui/?override_assignee_id=${deleted_override_assignee_id}`, {params}
      )
      .then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          addToast(response.data.message, {
            appearance: "success",
            autoDismiss: true,
          });
          handleRefresh();
          setisActiveButton(false);
          setdelete_retailer_override_assignee_list([]);
          setOpenDeleteModal(false);
        } else if (response.status === 404) {
          addToast(response.data.message, {
            appearance: "error",
            autoDismiss: true,
          });
          handleRefresh();
          setOpenDeleteModal(false);
        }
      })
      .catch((err) => {
        addToast("Something went wrong, please try again!!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const DeleteRetailerOverrideModal = (
    id,
    product_name,
    sku,
    inventory_location,
    state
  ) => {
    setOpenDeleteModal(true);
    setdeleteRetailerOverrideInventoryLocation(inventory_location);
    setdeleteRetailerOverrideSKU(sku);
    setdeleteRetailerOverrideState(state);
    setdeleteRetailerOverrideId(id);
    setdeleteRetailerOverrideProductName(product_name);
  };

  const EditRetailerOverrideModal = (
    id,
    variant,
    inventory_location,
    state,
    channel,
    sku,
    channel_id,
  ) => {
    setOpenEditModal(true);

    setupdateRetailerOverrideInventoryLocation(inventory_location);
    setupdateRetailerOverrideVariant(variant);
    setupdateRetailerOverrideState(state);
    setupdateRetailerOverrideChannel(channel === "null" ? "None" : channel);
    setupdateRetailerOverrideChannelId(channel_id);
    setupdateRetailerOverrideSku(sku);
    setupdateRetailerOverrideId(id);
  };

  const [exportButton, setExportButton] = useState({
    label: "Export",
    disabled: false,
  });

  const handleExportRetailers = () => {
    setExportButton({
      label: "Exporting...",
      disabled: true,
    });

    let new_params = params;
    new_params.retailer_override_assignee_ids = delete_retailer_override_assignee_list.map((item) => item.id);
    api
      .get("retailer-override/export/", {
        params: new_params,
      })
      .then((response) => response.data)
      .then((response) => {
        const success = response?.success | false;

        if (success) {
          addToast(response.message, {
            appearance: "success",
            autoDismiss: false,
          });
        } else {
          const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
          const filename = "retailers_" + timestamp + ".csv";
          fileDownload(response, filename);
        }

        setExportButton({
          label: "Export",
          disabled: false,
        });
      });
  };

  if (isAuthentication && !user.isAdmin) {
    window.location.href = window.env.APP_URL;
  }

  const updateInventoryLocations = (retailerOverrideIds) => {
    api
      .get("retailer-override/", {
        params: {
          retailer_override_ids: retailerOverrideIds,
        }
      })
      .then((response) => {
        if (response.status === 200 && response.data?.success) {
          const result = response.data.result;
          const updatedOrders = orders.map((item) => {
            const retailerOverride = result.find((order) => item.id === order.id);
            if (retailerOverride) {
              item.inventory_location = retailerOverride.inventory_location;
            }
            return item;
          });
          setOrders(updatedOrders);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /**
   * Clearing all the selected retailer overrides on 
   * complete update inventory location
   * @param {*}
   */
  const onCompleteStatusUpdate = () => {
    setRetailerOverrideList([]);
  };

  const handleSKU = (e) => {
    setPage(1);
    if (e == "__all__") {
      let status = [];
      let checkbox = document.querySelectorAll(".checkbox_state");
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = true;
        status.push({
          value: checkbox[i].value,
          label: checkbox[i].dataset.label,
        });
      }
      setFilter({ ...filter, sku: status });
    } else {
      let checkbox = [...new Set(filter.sku)];
      if (e.target.checked) {
        checkbox.push({ value: e.target.value, label: e.target.dataset.label });
      } else {
        checkbox = checkbox.filter((item) => item.value !== e.target.value);
      }
      setFilter({ ...filter, sku: checkbox });
    }
  }

  return (
    <>
      {isAuthentication && user.isAdmin && (
        <Container fluid>
          <div className={"pt-7"}>
            {isAuthentication && (
              <Row className={"mb-2"}>
                <Col xs={10} lg={"2"} className={"text-left"}></Col>
                <Col xs={2} lg={"10"} className={"text-right"}>
                  <div className={"d-none d-lg-inline-block mr-2"}>
                    <div className="mb-1 d-inline">
                      {/* 7278215305 - increase font size */}
                      <button
                        className="button-font-size btn btn-neutral btn-sm mr-2 p-9px"
                        onClick={handleRefresh}
                      >
                        <i className="fas fa-sync-alt"></i>
                      </button>
                    </div>

                    <Filter
                      name={"Brand"}
                      prefix={"brand"}
                      onChange={handleBrands}
                      newparams={params}
                      selected={filter.brands}
                      url={"/retailer/filter/brand/"}
                      onClear={() => clearFilter("brand")}
                    />
                    <Filter
                      name={"Product"}
                      prefix={"product"}
                      onChange={handleProducts}
                      newparams={params}
                      selected={filter.products}
                      url={"/retailer/filter/product/"}
                      onClear={() => clearFilter("product")}
                    />
                    <Filter
                      name={"State"}
                      prefix={"state"}
                      onChange={handleStates}
                      newparams={params}
                      selected={filter.states}
                      url={"order/filter/state/"}
                      inputSearch={true}
                      onClear={() => clearFilter("state")}
                    />
                    <Filter
                      name={"Inventory Location"}
                      prefix={"inventory_location"}
                      showSelectAll={false}
                      onChange={handleInventoryLocation}
                      newparams={params}
                      selected={filter.inventory_location}
                      url={"order/filter/inventory-location/"}
                      inputSearch={true}
                      onClear={() => clearFilter("inventory_location")}
                    />
                    <Filter
                      name={"SKUs"}
                      prefix={"sku"}
                      onChange={handleSKU}
                      newparams={params}
                      selected={filter.sku}
                      url={"/order/retailer-override/filter/sku/"}
                      inputSearch={true}
                      onClear={() => clearFilter("sku")}
                    />
                    {/* Jira: BCD-1584
                      Description: Export retailers list to a CSV file
                      Added by: ABHIJITH WARRIER */}
                    {/* 7278215305 - increase font size */}
                    <Button
                      color={"primary"}
                      size={"sm"}
                      disabled={exportButton.disabled}
                      className="button-font-size p-9px"
                      onClick={handleExportRetailers}
                    >
                      <i className="fas fa-file-csv"></i> {exportButton.label}
                    </Button>
                    {/* 7278215305 - increase font size */}
                    <Button
                      color={"primary"}
                      size={"sm"}
                      className="button-font-size p-9px"
                      onClick={(e) => setOpenModal(true)}
                    >
                      <i className="fas fa-file-csv"></i> Create
                    </Button>
                    <ProductsSearchForm
                      url={"retailer-assignment-product-list/"}
                      isOpen={openModal}
                      toggle={(e) => setOpenModal(false)}
                      loading={isLoading}
                    />
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              {isAuthentication && (
                <div className="col">
                  <Card className="shadow ">
                    <CardHeader className="border-0">
                      <Row>
                        <Col>
                          <h3
                            className="mb-0"
                            style={{
                              display: "inline-block",
                              paddingRight: "5px",
                            }}
                          >
                            Retailers <small>({totalOrders})</small>
                            <br />
                          </h3>

                          {clearall > 0 && (
                            <a
                              href={void 0}
                              className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                              onClick={handleClearAll}
                              id="clear_all_filters"
                            >
                              <i className="fas fa-times-circle"></i> Clear All
                              filters
                            </a>
                          )}

                        {/*  {retailerOverrideList.length === 50 && (*/}
                        {/*    <Button*/}
                        {/*        className="ml-2 bg-transparent border-none shadow-none text-primary"*/}
                        {/*        onClick={() => {*/}
                        {/*            setselectAllRetailerOverridesInThisView(!selectAllRetailerOverridesInThisView);*/}
                        {/*        }}*/}
                        {/*        size="sm">*/}
                        {/*        <u>*/}
                        {/*            {" "}*/}
                        {/*            {!selectAllRetailerOverridesInThisView*/}
                        {/*                ? " Select all Retailer Overrides in this view "*/}
                        {/*                : " All Retailer Overrides in this view are selected "}{" "}*/}
                        {/*        </u>*/}
                        {/*    </Button>*/}
                        {/*)}*/}
                        </Col>
                        <Col className="text-right">
                          {isActiveButton && user.isAdmin && (
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn text-primary"
                                href="#"
                                role="button"
                                size="sm"
                                color="white"
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-cogs" /> actions
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#"
                                  onClick={(e) => setOpenUpdateLocationModal(true)}
                                >
                                  <i className="fas fa-file-invoice" />
                                  Update Inventory Location
                                </DropdownItem>
                                <DropdownItem
                                  href="#"
                                  onClick={(e) => setOpenDeleteModal(true)}
                                  className="text-danger"
                                >
                                  <i className="fas fa-file-invoice" />
                                  Delete Records
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )}
                        </Col>
                        <Col sm="12">
                          <BadgePillFilter
                            data={filter}
                            onClick={removeBadge}
                          />
                        </Col>
                      </Row>
                    </CardHeader>
                    {isLoading ? (
                      <div className="text-center mt-3 mb-3">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col" className="pl-3 pr-3">
                                <input
                                  type="checkbox"
                                  name={"check_orders"}
                                  id={"checkbox_orders"}
                                  ref={refOrdersCheckbox}
                                  defaultChecked={refOrdersCheckbox.current}
                                  onChange={handleOrdersCheckbox}
                                />
                              </th>
                              <th scope="col" className="pl-3 pr-3">
                                <Sort onClick={() => handleSort(sort, "created_at", setSortOrder, setSort)} sortingOrder={sortOrder} column="created_at">
                                  Created at
                                </Sort>
                              </th>

                              <th scope="col" className="pl-3 pr-3">
                                Brand
                              </th>

                              <th scope="col" className="pl-3 pr-3">
                                Product Name
                              </th>
                              <th scope="col" className="pl-3 pr-3">
                                SKU
                              </th>

                              <th scope="col" className="pl-3 pr-3">
                                <Sort
                                  onClick={() =>handleSort(sort, "inventory_location", setSortOrder, setSort)} sortingOrder={sortOrder} column="inventory_location">
                                  Inventory Location Name
                                </Sort>
                              </th>

                              <th scope="col" className="pl-3 pr-3">
                                <Sort onClick={() => handleSort(sort, "state", setSortOrder, setSort)} sortingOrder={sortOrder} column="state">
                                  State Name
                                </Sort>
                              </th>

                              <th scope="col" className="pl-3 pr-3">
                                {/* <Sort onClick={() => handleSort("channel")}> */}
                                Sales Channel
                                {/* </Sort> */}
                              </th>

                              <th scope="col" className="pl-3 pr-3">
                                <Sort onClick={() => handleSort(sort, "user", setSortOrder, setSort)} sortingOrder={sortOrder} column="user">
                                  User Created Name
                                </Sort>
                              </th>

                              <th scope="col" className="pl-3 pr-3"></th>
                              {/*<th scope="col"/>*/}
                            </tr>
                          </thead>
                          <tbody>
                            {orders.map((order) => (
                              <tr>
                                <td key={order.id} className="pl-3 pr-3">
                                  <input
                                    type="checkbox"
                                    name={order.shopify_id}
                                    value={order.id}
                                    id={"checkbox_" + order.id}
                                    className={"orderCheckbox"}
                                    onChange={(e) =>
                                      handleOverrideAssigneeCheckbox(
                                        e,
                                        order.id,
                                        order.product_name,
                                        order.inventory_location,
                                        order.inventory_location,
                                        order.state,
                                        order.variant
                                      )
                                    }
                                  />
                                </td>
                                <td className="pl-3 pr-3 ">
                                  <Moment format="M/D/Y h:mm:ssa" withTitle>
                                    {order.created_at}
                                  </Moment>
                                </td>
                                <td className="pl-3 pr-3 ">
                                  {order.brand_name}
                                </td>
                                <td className="pl-3 pr-3 ">
                                  {order.product_name}
                                </td>
                                <td className="pl-3 pr-3 ">{order.sku}</td>
                                <td className="pl-3 pr-3 ">
                                  {order.inventory_location}
                                </td>
                                <td className="pl-3 pr-3 ">{order.state}</td>
                                <td className="pl-3 pr-3 ">{order.channel}</td>
                                <td className="pl-3 pr-3 ">{order.user ?? "-"}</td>
                                <td className="pl-3 pr-3 ">
                                  <i
                                    className="fa fa-edit mr-3"
                                    style={{ color: "" }}
                                    onClick={(e) =>
                                      EditRetailerOverrideModal(
                                        `${order.id}`,
                                        `${order.variant}`,
                                        `${order.inventory_location}`,
                                        `${order.state}`,
                                        `${order.channel}`,
                                        `${order.sku}`,
                                        `${order.channel_id}`,
                                      )
                                    }
                                  ></i>
                                  <i
                                    className="fa fa-trash"
                                    style={{ color: "red" }}
                                    onClick={(e) =>
                                      DeleteRetailerOverrideModal(
                                        `${order.id}`,
                                        `${order.product_name}`,
                                        `${order.sku}`,
                                        `${order.inventory_location}`,
                                        `${order.state}`
                                      )
                                    }
                                  ></i>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <PagePagination pagination={pagination} handlePagination={handlePagination} page={page} totalCount={totalOrders} />
                      </>
                    )}
                  </Card>
                </div>
              )}
            </Row>
          </div>
        </Container>
      )}

      <Modal
        style={{ marginTop: "100px" }}
        isOpen={openDeleteModal}
        size={"lg"}
        onExit={toggle}
        toggle={() => toggle()}
      >
        <ModalHeader className="bg-primary text-white">
          <span className="text-white h3">Retailer Assignment Overrides</span>
        </ModalHeader>
        <ModalBody>
          <h3 className="mb-4">
            Are you sure you want to delete the following mapping:
          </h3>
          {delete_retailer_override_assignee_list.length > 0 ? (
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" className="pl-3 pr-3">
                    Product Name
                  </th>
                  <th scope="col" className="pl-3 pr-3">
                    SKU
                  </th>
                  <th scope="col" className="pl-3 pr-3">
                    Inventory Location
                  </th>
                  <th scope="col" className="pl-3 pr-3">
                    State
                  </th>
                </tr>
              </thead>
              <tbody>
                {delete_retailer_override_assignee_list.map((record) => (
                  <tr>
                    <td className="pl-3 pr-3">
                      {record && record.product_name}
                    </td>
                    <td className="pl-3 pr-3">{record && record.sku}</td>
                    <td className="pl-3 pr-3">
                      {record && record.inventory_location}
                    </td>
                    <td className="pl-3 pr-3">{record && record.state}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col" className="pl-3 pr-3">
                    Product Name
                  </th>
                  <th scope="col" className="pl-3 pr-3">
                    SKU
                  </th>
                  <th scope="col" className="pl-3 pr-3">
                    Inventory Location
                  </th>
                  <th scope="col" className="pl-3 pr-3">
                    State
                  </th>
                </tr>
              </thead>
              <tbody>
                <td className="pl-3 pr-3">
                  {deleteRetailerOverrideProductName &&
                    deleteRetailerOverrideProductName}
                </td>
                <td className="pl-3 pr-3">
                  {deleteRetailerOverrideSKU && deleteRetailerOverrideSKU}
                </td>
                <td className="pl-3 pr-3">
                  {deleteRetailerOverrideInventoryLocation &&
                    deleteRetailerOverrideInventoryLocation}
                </td>
                <td className="pl-3 pr-3">
                  {deleteRetailerOverrideState && deleteRetailerOverrideState}
                </td>
              </tbody>
            </Table>
          )}
          {selectAllRetailerOverridesInThisView && (
            <>
              <h5 className="text-danger fw-500">
                This action will affect {totalOrders} Retailer Overrides in current view
              </h5>
            </>
          )}
        </ModalBody>
        <ModalFooter className="mt-0 pt-0">
          <Button color="secondary" onClick={(e) => setOpenDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            color="danger"
            id="delete_btn"
            onClick={(e) =>
              DeleteRetailerOverride(
                delete_retailer_override_assignee_list.length > 0
                  ? delete_retailer_override_assignee_list
                  : deleteRetailerOverrideId
              )
            }
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <EditOverrideRetailerSearchForm
        url={"retailer-assignment-product-list/"}
        isOpen={openEditModal}
        toggle={(e) => setOpenEditModal(false)}
        loading={isLoading}
        default_state_value={updateRetailerOverrideState}
        default_inventory_location_value={
          updateRetailerOverrideInventoryLocation
        }
        default_sale_channel={updateRetailerOverrideChannel}
        default_sale_channel_id={updateRetailerOverrideChannelId}
        default_variant_value={updateRetailerOverrideVariant}
        default_id={updateRetailerOverrideId}
        default_sku_value={updateRetailerOverrideSku}
      />
      <UpdateRetailerAssignmentLocationModal
        isOpen={openUpdateLocationModal}
        toggle={(e) => setOpenUpdateLocationModal(false)}
        retailerOverrideList={retailerOverrideList}
        updateInventoryLocations={updateInventoryLocations}
        totalOrdersCount={totalOrders}
        filters={filter}
        selectAllRetailerOverridesInThisView={selectAllRetailerOverridesInThisView}
        onComplete={onCompleteStatusUpdate}
      />
    </>
  );
};

export default RetailerOverride;