import React, { useEffect, useState, useContext, useRef } from "react";
import { Table, Button, FormGroup, Card, CardHeader, Col, Container, Row, CardFooter, Pagination, PaginationItem, 
  PaginationLink, Spinner, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { api } from "services/api";
import Dropdown from "../../components/UploadCSV/dropdown";
import { useToasts } from "react-toast-notifications";
import Filter from "../../components/Filters/Filter";
import { UploadCSVModalForm } from "../../components/UploadCSV";
import { AuthContext } from "../../contexts/AuthContext";
import BadgePillFilter from "components/Filters/Badge";
import StagingQuantity from "components/Filters/StagingQuantity";
import StagingPrice from "components/Filters/StagingPrice";
import BulkSkuModule from "components/SkuMapping/list";
import PagePagination from "feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import { Sort, handleSort } from "../../utils/commonFunctions";
import "../../utils/commonStyles.css";

function StagingListing(props) {
  const { addToast } = useToasts();
  const [sort, setSort] = useState("stage_inventory_id");
  const [sortOrder, setSortOrder] = useState({order: "", columnName: ""});
  const [unmappedProducts, setUnmappedProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [barCartSku, setBarCartSku] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [updatedSku, setUpdatedSku] = React.useState({});
  const [page, setPage] = useState(1);
  const [params, setParams] = useState([]);
  const [selected, setSelected] = useState([]);
  const [filter, setFilter] = useState({
    productNames: [],
    locationIds: [],
    sizeIds: [],
    stagingQuantityMin: [],
    stagingQuantityMax: [],
    stagingPriceMin: [],
    stagingPriceMax: [],
  })
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [clearall, setClearAll] = useState(false)
  const [pagination, setPagination] = useState({
    previous: null,
    next: null,
    pages: () => {
      return []
    }
  });
  const [refresh, setRefresh] = useState(false);

  const [inventoryModal, setInventoryModal] = useState({
    uploadCSV: false,
  });
  const { user, isAuthentication } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState({
    updatedSku: false,
  });
  const [ordersForBulkUpdate, setOrdersForBulkUpdate] = useState([]);

  // Check weather screen size is mobile or desktop
  const isMobile = useMobileDetection(mobileScreenThresholdValue);

  /**
   * Setting up the new_params and passing to the backend for pagination and sort funcationality 
   * @returns 
   */
  const getParams = () => {
    let new_params = {
      page,
      sorted: sort,
      page_size: window.env.PAGE_SIZE
    };
    if (filter.search?.length > 0) {
      new_params.search = filter.search
      setClearAll(true);
    }

    if (filter?.productNames.length > 0) {
      new_params.productName = [];
      filter.productNames.map((item) => new_params.productName.push(item.value));
      setClearAll(true);
    }

    if (filter?.locationIds.length > 0) {
      new_params.locationId = [];
      filter.locationIds.map((item) => new_params.locationId.push(item.value));
      setClearAll(true);
    }

    if (filter?.sizeIds.length > 0) {
      new_params.sizeIds = [];
      filter.sizeIds.map((item) => new_params.sizeIds.push(item.value));
      setClearAll(true);
    }

    if (filter?.stagingQuantityMin > 0) {
      new_params.staging_quantity_min = filter.stagingQuantityMin;
      setClearAll(true);
    }

    if (filter?.stagingQuantityMax > 0) {
      new_params.staging_quantity_max = filter.stagingQuantityMax;
      setClearAll(true);
    }

    if (filter?.stagingPriceMin > 0) {
      new_params.staging_price_min = filter.stagingPriceMin;
      setClearAll(true);
    }

    if (filter?.stagingPriceMax > 0) {
      new_params.staging_price_max = filter.stagingPriceMax;
      setClearAll(true);
    }

    setParams(new_params);
    return new_params;
  };

  /**
   * Refresh the selected filter 
   */
  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  /**
   * Set the value of barCartSku for given inventory id
   * @param {*} inventoryId inventory id of selected Dropdown
   * @param {*} value updated value
   */
  const onChangeSku = (inventoryId, value) => {
    const data = updatedSku;
    data[inventoryId] = value;
    setUpdatedSku(data);
    setShowConfirmButton(true);
  };

  // OnClick on confirm button product move to inventory table and status = mapped
  const handleConfirm = () => {
    const data = Object.keys(updatedSku).map((key) => ({
      stage_inventory_id: key,
      variant_sku: updatedSku[key]
    }));
    setLoading(true);
    api.post("/unmapped-inventory", data)
      .then(response => {
        setLoading(false);
 
        if (response?.data?.statusCode === 200) {
          addToast('Saved Successfully', { appearance: 'success', autoDismiss: true });
          handleRefresh();
        } else {
          let message = "Something went wrong!";
          if (response?.data?.message) {
            message = response.data.message;
          }
          addToast(message, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 30000 });
        }
      }).catch(error => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleSync = () => {
    api.post("/csv-inventory-mapping")
    addToast("File Will Be Processed In Couple Of Minutes.", { appearance: 'success', autoDismiss: true });
  };

  // API call to get the unmapped records from Stage Inventory table
  useEffect(() => {
    setLoading(true);
    const new_params = getParams();
    api
      .get("/unmapped-inventory", {
        params: new_params
      })
      .then((response) => {
        setLoading(false);
        setUnmappedProducts(response.data.data.rows);
        setTotalProducts(response.data.count);

        const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
        const previous = (page !== 1) ? page - 1 : 1
        const next = (page !== totalPages) ? page + 1 : totalPages
        setPagination({
          previous,
          next,
          pages: () => {
            let startCount = 1;
            let endCount = isMobile ? 4 : 12;
            let numberCount = Math.round(endCount / 2);
            const pageList = [];
            if (numberCount < 0) numberCount = 1;
            startCount = page - numberCount
            if (startCount <= 0) startCount = 1;           
            if (page !== 1) endCount = page + numberCount;
            if (endCount > totalPages) endCount = totalPages;
            if (totalPages >= endCount) {
              for (let i = startCount; i <= endCount; i++) {
                pageList.push(i)
              }
            } else if (totalPages >= 1) {
              for (let i = 1; i <= totalPages; i++) {
                pageList.push(i)
              }
            }
            return pageList;
          }
        })
        setLoading(false);
        delete new_params['page'];
        delete new_params['page_size'];
        delete new_params['sorted'];
        setParams(new_params);
      }).catch((error) => {
        console.error(error);
      });
  }, [page, sort, filter, refresh]);

  /**
  * Set the value of page
  * @param {*} page set updated page value
  */
  const handlePagination = page => {
    setPage(page);
  };

  // Set the search value
  let timer = null;
  const handleSearch = (e) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setFilter({
        ...filter,
        search: e.target.value
      })
    }, 750);
  };

  // API call to get the barcartsku from combination of product_variant and product_product tables
  useEffect(() => {
    setLoading(true);
    api
      .get("/barcart-sku")
      .then((response) => {
        if (response.data.data) {
          const options = response.data.data.map((option) => ({
            label: option.label,
            value: option.productBarcartSku,
          }));
          setBarCartSku(options);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  /**
   * 
   * @param {*} e set the inventoryProduct = Id and value for filtering 
   */
  const handleProductName = (e) => {
    let checkbox = [...new Set(filter.productNames)];

    if (e.target.checked) {
      checkbox.push({ value: e.target.value, label: e.target.dataset.label });
    } else {
      checkbox = checkbox.filter((item) => item.value !== e.target.value);
    }

    setFilter({ ...filter, productNames: checkbox });
  };

/**
 * 
 * @param {*} e set the inventoryLocationId for filtering
 */
  const handleLocationId = (e) => {
    let checkbox = [...new Set(filter.locationIds)];

    if (e.target.checked) {
      checkbox.push({ value: e.target.value, label: e.target.dataset.label });
    } else {
      checkbox = checkbox.filter((item) => item.value !== e.target.value);
    }

    setFilter({ ...filter, locationIds: checkbox });
  };


/**
 * 
 * @param {*} e set the sizeIds for filtering
 */
 const handleSizeId = (e) => {
  let checkbox = [...new Set(filter.sizeIds)];

  if (e.target.checked) {
    checkbox.push({ value: e.target.value, label: e.target.dataset.label });
  } else {
    checkbox = checkbox.filter((item) => item.value !== e.target.value);
  }

  setFilter({ ...filter, sizeIds: checkbox });
};


  /**
  * 
  * @param {*} prefix clear the filter value
  */
  const clearFilter = (prefix) => {
    if (prefix === "productName") {
      setFilter({
        ...filter,
        productNames: [],
      });
    } else if (prefix === "locationId") {
      setFilter({
        ...filter,
        locationIds: [],
      });

      setClearAll(false);
    } else if (prefix === "sizeId") {
      setFilter({
        ...filter,
        sizeIds: [],
      });

      setClearAll(false);
    };    
  };

  const exitUploadCSVModal = () => {
    setInventoryModal({
      uploadCSV: false,
    });
  };

  /**
   * Render confirm button
   * @returns DOM
   */
  const renderConfirmButton = () => {
    return (
        showConfirmButton ?
          <Button
            size={"sm"}
            color={"primary"}
            disabled={isLoading}
            onClick={handleConfirm}
          >
            Confirm
            {
              isLoading
              &&
              <Spinner size="sm" color="light" />
            }
          </Button>
        :
        null
    );
  };

  const removeBadge = (e) => {

    if (e.component === "productNames") {
      let items = filter.productNames;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, productNames: items });
    } else if (e.component === "locationIds") {
      let items = filter.locationIds;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, locationIds: items });
    } else if (e.component === "sizeIds") {
      let items = filter.sizeIds;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, sizeIds: items });
    } else if (e.component === "stagingQuantityMin") {
      setFilter({ ...filter, stagingQuantityMin: null });
    } else if (e.component === "stagingQuantityMax") {
      setFilter({ ...filter, stagingQuantityMax: null });
    } else if (e.component === "stagingPriceMin") {
      setFilter({ ...filter, stagingPriceMin: null });
    } else if (e.component === "stagingPriceMax") {
      setFilter({ ...filter, stagingPriceMax: null });
    } else if (e.component === "search") {
      setFilter({ ...filter, search: null });
      document.getElementById("retailerStagingSearch").value = "";
    } else if (e.component === "selected") {
      let items = selected;
      items = items.filter((item) => item.id !== e.item.id);

      if (e.component === "productNames") {
        let items = filter.productNames;
        items = items.filter((item) => item.value !== e.item.value);
        setFilter({ ...filter, productNames: items });
      } else if (e.component === "locationIds") {
        let items = filter.locationIds;
        items = items.filter((item) => item.value !== e.item.value);
        setFilter({ ...filter, locationIds: items });
      } else if (e.component === "sizeIds") {
        let items = filter.sizeIds;
        items = items.filter((item) => item.value !== e.item.value);
        setFilter({ ...filter, sizeIds: items });
      } else if (e.component === "stagingQuantityMin") {
        setFilter({ ...filter, stagingQuantityMin: null });
      } else if (e.component === "stagingQuantityMax") {
        setFilter({ ...filter, stagingQuantityMax: null });
      } else if (e.component === "stagingPriceMin") {
        setFilter({ ...filter, stagingPriceMin: null });
      } else if (e.component === "stagingPriceMax") {
        setFilter({ ...filter, stagingPriceMax: null });
      } else if (e.component === "search") {
        setFilter({ ...filter, search: null });
        document.getElementById("retailerStagingSearch").value = "";
      } else if (e.component === 'selected') {
        let items = selected
        items = items.filter(item => item.id !== e.item.id)

        setSelected(items)
        let checkbox = document.getElementById('checkbox_' + e.item.id)
        if (checkbox) {
          checkbox.checked = false
        }
      }
      setClearAll(false)
    }
    setClearAll(false);
  };

  const handleClearAll = () => {
    setClearAll(false);
    setSelected([]);
    document.getElementById("retailerStagingSearch").value = "";
    setFilter({
      productNames: [],
      locationIds: [],
      sizeIds: [],
      stagingQuantityMin: [],
      stagingQuantityMax: [],
      stagingPriceMin: [],
      stagingPriceMax: [],
    });
  };

  const refStagingRecordsCheckbox = useRef(false)

  const handleStagingRecordCheckbox = (e) => {

      refStagingRecordsCheckbox.current = e.target.checked
      let checkboxes = document.querySelectorAll('.stagingRecordsCheckbox');

      let items = [];
      for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = e.target.checked;
          if (e.target.checked === true) {
              items.push({id: parseInt(checkboxes[i].value), title: checkboxes[i].name})
          }
      }
      setSelected(items)

  }

  const handleCheckbox = (e) => {
    let checkbox = [...new Set(selected)]

    if (e.target.checked) {
        checkbox.push({id: e.target.value, title: e.target.name})
    } else {
      // !== strict inequality operator checking the order id available or not, so that's why we use != inequality operator
        checkbox = checkbox.filter(item => item.id != e.target.value)
    }

    setSelected(checkbox)

}

const refMinStagingQuantity = useRef(null);
const refMaxStagingQuantity = useRef(null);

const handleStagingQuantity = (e) => {
  e.preventDefault();
  let valueMin = null;
  let valueMax = null;

  if (refMinStagingQuantity.current > 0) {
    valueMin = refMinStagingQuantity.current;
  }

  if (refMaxStagingQuantity.current > 0) {
    valueMax = refMaxStagingQuantity.current;
  }

  setFilter({ ...filter, stagingQuantityMin: valueMin, stagingQuantityMax: valueMax });
};

const refMinStagingPrice = useRef(null);
const refMaxStagingPrice = useRef(null);

const handleStagingPrice = (e) => {
  e.preventDefault();
  let valueMin = null;
  let valueMax = null;

  if (refMinStagingPrice.current > 0) {
    valueMin = refMinStagingPrice.current;
  }

  if (refMaxStagingPrice.current > 0) {
    valueMax = refMaxStagingPrice.current;
  }

  setFilter({ ...filter, stagingPriceMin: valueMin, stagingPriceMax: valueMax });
};

  /**
   * Handler to open Bulk Sku Mapping
   * @param {*} ordersForEmail 
   */
   const handleOpenBulkSkuUpdateModal = (ordersForSkuMapping) => {
    setOrdersForBulkUpdate(ordersForSkuMapping);
    setOpenModal({ skuUpdate: true });
  };
  return (
    <>
    { isAuthentication && (
    <Container className="pt-7" fluid>
          <UploadCSVModalForm
            isOpen={inventoryModal.uploadCSV}
            onExit={exitUploadCSVModal}
          />
          <BulkSkuModule
            orders={ordersForBulkUpdate}
            options={barCartSku}
            stagingRecords={selected}
            isOpen={openModal.skuUpdate}
            onExit={() => setOpenModal({ skuUpdate: !openModal.skuUpdate })}
          />
      <Row>
        <div className="col">
          <Row className={"mb-2"}>
            <Col xs={10} lg={"2"} className={"text-left"}>
              <input type="text"
                className={"button-font-size form-control form-control-sm"}
                placeholder={"Product Name or SKU"}
                id="retailerStagingSearch"
                defaultValue={filter.search}
                onKeyDown={handleSearch}
                newparams={""}
              />
            </Col>
            <Col xs={2} lg={"10"} className={"text-right"}>
              <div className={"d-none d-lg-inline-block mr-2"}>
                {/* 7278215305 - increase font size */}
                <button
                  className="button-font-size btn btn-neutral btn-sm p-9px"
                  onClick={handleRefresh}
                >
                  <i className="fas fa-sync-alt"></i>
                </button>

                <Filter
                  name={"Product"}
                  prefix={"productName"}
                  onChange={handleProductName}
                  newparams={params}
                  selected={filter.productNames}
                  url={"/inventory/filter/product"}
                  onClear={() => clearFilter("productName")}
                />

                <Filter
                  name={"Location"}
                  prefix={"locationId"}
                  onChange={handleLocationId}
                  newparams={params}
                  selected={filter.locationIds}
                  url={"/inventory/filter/location"}
                  onClear={() => clearFilter("locationId")}
                />

                <Filter
                  name={"Size"}
                  prefix={"sizeId"}
                  onChange={handleSizeId}
                  newparams={params}
                  selected={filter.sizeIds}
                  url={"/inventory/filter/size"}
                  onClear={() => clearFilter("sizeId")}
                />

                <StagingQuantity
                  onSubmit={handleStagingQuantity}
                  valueMin={filter.stagingQuantityMin}
                  valueMax={filter.stagingQuantityMax}
                  refMinDay={refMinStagingQuantity}
                  refMaxDay={refMaxStagingQuantity}
                />

                <StagingPrice
                  onSubmit={handleStagingPrice}
                  valueMin={filter.stagingPriceMin}
                  valueMax={filter.stagingPriceMax}
                  refMinDay={refMinStagingPrice}
                  refMaxDay={refMaxStagingPrice}
                />

              {  (user.isRetailer) || (user.isAdmin) ?
                <Button className="button-font-size p-8px" type={"submit"} size={"sm"} color={"primary"} onClick={(e) => setInventoryModal({ uploadCSV: !inventoryModal.uploadCSV })}>
                  Upload CSV
                  {isLoading && <Spinner size="sm" color="light" />}
                </Button>
              : undefined}
                <Button className="button-font-size p-8px" type={"submit"} size={"sm"} color={"primary"} onClick={handleSync}>
                  Sync Now
                  {isLoading && <Spinner size="sm" color="light" />}
                </Button>
              { renderConfirmButton() }
              </div>
            </Col>
          </Row>
          <Card className="shadow ">
            <CardHeader className="border-0">
              <Row>
                <Col>
                  <h3 className="mb-0"> Connect your inventory to Flaviar Checkout <small>({totalProducts})</small> </h3>
                  {clearall && (
                    <a
                      href={void 0}
                      className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                      onClick={handleClearAll}
                      id="clear_all_filters"
                    >
                      <i className="fas fa-times-circle"></i> Clear All
                      filters
                    </a>
                  )}
                </Col>
                <Col sm="12">
                  <BadgePillFilter
                    title="Record"
                    data={filter}
                    selected={selected}
                    onClick={removeBadge}
                  />
                </Col>
                <Col className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn text-primary"
                    href="#"
                    role="button"
                    size="sm"
                    color="white"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-cogs" /> actions
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-arrow"
                    right
                  >
                    <DropdownItem
                      href="#"
                      onClick={(e) => handleOpenBulkSkuUpdateModal()}
                    >
                      <i className="fas fa-folder-open" /> Bulk SKU Mapping
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
              </Row>
            </CardHeader>
            {isLoading ?
              <div className="text-center mt-3 mb-3">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div> :
              <>
                <Table className="align-items-center table-flush" responsive>
                  <thead className=" thead-light">
                    <tr>
                      <th scope="col" className="pl-3 pr-3">
                        <input
                            type="checkbox"
                            name={"check_orders"}
                            id={"checkbox_orders"}
                            ref={refStagingRecordsCheckbox}
                            defaultChecked={refStagingRecordsCheckbox.current}
                            onChange={handleStagingRecordCheckbox}/>
                      </th>
                      <th scope="col" className="pl-3 pr-3"> <Sort onClick={() =>
                        handleSort(sort, "product_retailer_sku", setSortOrder, setSort)} sortingOrder={sortOrder} column="product_retailer_sku"> SKU </Sort> </th>
                      <th scope="col" className="pl-3 pr-3"> Location Name </th>
                      <th scope="col" className="pl-3 pr-3"> <Sort onClick={() =>
                        handleSort(sort, "name", setSortOrder, setSort)} sortingOrder={sortOrder} column="name"> Product Name </Sort> </th>
                      <th scope="col" className="pl-3 pr-3"> <Sort onClick={() =>
                        handleSort(sort, "price", setSortOrder, setSort)} sortingOrder={sortOrder} column="price"> Retail Price </Sort> </th>
                      <th scope="col" className="pl-3 pr-3"> <Sort onClick={() =>
                        handleSort(sort, "size", setSortOrder, setSort)} sortingOrder={sortOrder} column="size"> Size </Sort> </th>
                      <th scope="col" className="pl-3 pr-3"> <Sort onClick={() =>
                        handleSort(sort, "quantity", setSortOrder, setSort)} sortingOrder={sortOrder} column="quantity"> Quantity </Sort> </th>
                      <th scope="col" className="pl-3 pr-3"> UPC </th>
                      <th scope="col" className="pl-3 pr-3"> <Sort onClick={() =>
                        handleSort(sort, "created_at", setSortOrder, setSort)} sortingOrder={sortOrder} column="created_at"> Last Synced </Sort> </th>
                      <th scope="col" className="pl-5 pr-5"> BarCart SKU </th>
                    </tr>
                  </thead>
                  <tbody>
                    {unmappedProducts.map((product) => (
                      <tr key={product.stage_inventory_id}>
                        <td  className={"pr-3 pl-3"}>
                          <input type="checkbox"
                            name={product.name}
                            value={product.stage_inventory_id}
                            id={"checkbox_"+product.stage_inventory_id}
                            className={"stagingRecordsCheckbox"}
                            onChange={(e) => handleCheckbox(e)}
                            defaultChecked={product?.checked}/>
                        </td>
                        <td>{product.product_retailer_sku}</td>
                        <td>{product.store_code}</td>
                        <td>{product.name}</td>
                        <td>$ {product.price}</td>
                        <td>{product.size}</td>
                        <td>{product.quantity}</td>
                        <td>{product.upccode}</td>
                        <td>{product.created_at}</td>
                        <td>
                          <Dropdown
                            onChange={(value) => onChangeSku(product.stage_inventory_id, value)}
                            options={barCartSku}
                            placeholder="Select Product"
                            zIndex={100}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <PagePagination pagination={pagination} handlePagination={handlePagination} page={page} totalCount={totalProducts} />
              </>}
          </Card>
        </div>
      </Row>
      <FormGroup className={"mt-3 text-right"}>
        { renderConfirmButton() }
      </FormGroup>
    </Container>
  )}
  </>
  );
}

export default StagingListing;
