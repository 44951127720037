import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, Button } from "reactstrap";
import { api } from "services/api";
import { AuthContext } from "contexts/AuthContext";
import { ComponentContext } from "../../contexts/ComponentContext";
import Filter from "../../components/Filters/Filter";
import { ChargebackStatusModal } from "../../components/ShippingChargebacks";
import fileDownload from "js-file-download";
import moment from "moment";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import DateRange from "../../components/Filters/DateRange";
import BadgePillFilter from "../../components/Filters/Badge";
import { getParams, removeBadge, onFilterChange, clearFilter } from "../../utils/commonFunctions";
import PagePagination from "../../feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import ChargebacksTable from "./ChargebacksTable";
import useFilter from "../../customHooks/useFilter";
import { removeAllURLParams, addURLParam, processURLSearchParams } from "../../utils/urls";
import { useLocation } from "react-router-dom";
import "../../utils/commonStyles.css";

const DiscountCodeChargebacks = props => {
    const [chargebacks, setChargeback] = useState([]);
    const [chargebackCount, setChargebackCount] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        }
    });

    const [sort, setSort] = useState("-id");
    const [sortOrder, setSortOrder] = useState({ order: "", columnName: "" });
    const [isLoading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [selected, setSelected] = useState([]);
    const [params, setParams] = useState([]);
    const [clearall, setClearAll] = useState(false);
    const [status, setStatus] = useState([]);
    const [totalChargebackAmount, setTotalChargebackAmount] = useState([]);
    const [filter, setFilter, clearFilterState] = useFilter({});
    const [openModal, setOpenModal] = useState({
        updateChargebackRecords: false
    });
    const [orderId, setOrderId] = useState();
    const cancelToken = React.useRef(undefined);
    const [selectAllDiscountCodeChargebacksInThisView, setselectAllDiscountCodeChargebacksInThisView] = useState(false);
    const { addToast } = useToasts();

    // Check weather screen size is mobile or desktop
    const isMobile = useMobileDetection(mobileScreenThresholdValue);

    const handleCheckbox = e => {
        let checkbox = [...selected];
        if (e.target.checked) {
            checkbox.push({ id: e.target.value, title: e.target.name });
        } else {
            // !== strict inequality operator checking the order id available or not, so that's why we use != inequality operator
            checkbox = checkbox.filter(item => item.title !== e.target.value);
        }
        setSelected(checkbox);
    };

    const [exportButton, setExportButton] = useState({
        label: "Export",
        disabled: false,
    });

    const handleDateRange = (start, end) => {
        setFilter({
            ...filter,
            // 6367053025 - Generic Date Filter bug changes
            discountCodeChargebackStartDate: start,
            discountCodeChargebackEndDate: end
        });
        addURLParam(window, "discountCodeChargebackStartDate", start);
        addURLParam(window, "discountCodeChargebackEndDate", end);
    };

    const handleChargebacksDateRange = (start, end) => {
        setFilter({
            ...filter,
            chargebackStartDate: start,
            chargebackEndDate: end
        });
        addURLParam(window, "chargebackStartDate", start);
        addURLParam(window, "chargebackEndDate", end);
    };

    useEffect(() => {
        api.get("/order/shipping-status/")
            .then(response => {
                setStatus(response.data.results);
            })
            .catch(error => console.log(error));
    }, []);

    const { component } = useContext(ComponentContext);

    useEffect(() => {
        setLoading(true);
        let discountCodeChargebackAmtParams = getParams(filter);
        let chargebackParams = getParams(filter);
        discountCodeChargebackAmtParams = {
          ...discountCodeChargebackAmtParams,
          page,
          ordering: sort
        };
        chargebackParams = {
          ...chargebackParams,
          page,
          ordering: sort
        };
        if (typeof cancelToken.current !== 'undefined') {
          cancelToken.current !== undefined && cancelToken.current.cancel("Operation canceled due to new request.");
        }
        cancelToken.current = axios.CancelToken.source();
        Promise.all([
          api.get("discount/total-chargeback-amount/", {
            params: discountCodeChargebackAmtParams,
          }),
          api.get("discount-code-chargebacks/", {
            params: chargebackParams,
            cancelToken: cancelToken.current.token
          })
        ])
          .then(([chargebackResponse, listingResponse]) => {
            setTotalChargebackAmount(chargebackResponse.data.results);
            setChargeback(listingResponse.data.results);
            setChargebackCount(listingResponse.data.count);
            const totalPages = Math.ceil(listingResponse.data.count / window.env.PAGE_SIZE);
            const previous = page !== 1 ? page - 1 : 1;
            const next = page !== totalPages ? page + 1 : totalPages;
            setPagination({
              previous,
              next,
              pages: () => {
                let startCount = 1;
                let endCount = isMobile ? 4 : 12;
                let numberCount = Math.round(endCount / 2);
                const pageList = [];
                if (numberCount < 0) numberCount = 1;
                startCount = page - numberCount;
                if (startCount <= 0) startCount = 1;
                if (page !== 1) endCount = page + numberCount;
                if (endCount > totalPages) endCount = totalPages;
                if (totalPages >= endCount) {
                  for (let i = startCount; i <= endCount; i++) {
                    pageList.push(i);
                  }
                } else if (totalPages >= 1) {
                  for (let i = 1; i <= totalPages; i++) {
                    pageList.push(i);
                  }
                }
                return pageList;
              }
            });
            setLoading(false);
            // Clean up unnecessary parameters
            delete discountCodeChargebackAmtParams["page"];
            delete discountCodeChargebackAmtParams["ordering"];
            delete chargebackParams["page"];
            delete chargebackParams["ordering"];
            setParams(chargebackParams); // Set params for listing
          })
          .catch(err => {
            console.log(err);
          });
      }, [page, sort, filter, component, refresh, isMobile]);

    // useEffect(() => {
    //     setLoading(true);
    //     let new_params = getParams(filter, setClearAll);
    //     console.log("new params",new_params);
    //     new_params = {
    //         ...new_params,
    //         page,
    //         ordering: sort
    //     };
    //     if (typeof cancelToken.current != undefined) {
    //         cancelToken.current !== undefined && cancelToken.current.cancel("Operation canceled due to new request.");
    //     }
    //     cancelToken.current = axios.CancelToken.source();
    //     api.get("discount-code-chargebacks/", {
    //         params: new_params,
    //         cancelToken:cancelToken.current.token
    //     })
    //         .then(response => {
    //             setChargeback(response?.data?.results);
    //             setChargebackCount(response?.data?.count);

    //             const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
    //             const previous = page !== 1 ? page - 1 : 1;
    //             const next = page !== totalPages ? page + 1 : totalPages;
    //             setPagination({
    //                 previous,
    //                 next,
    //                 pages: () => {
    //                     let startCount = 1;
    //                     let endCount = isMobile ? 4 : 12;
    //                     let numberCount = Math.round(endCount / 2);
    //                     const pageList = [];
    //                     if (numberCount < 0) numberCount = 1;
    //                     startCount = page - numberCount;
    //                     if (startCount <= 0) startCount = 1;
    //                     if (page !== 1) endCount = page + numberCount;
    //                     if (endCount > totalPages) endCount = totalPages;
    //                     if (totalPages >= endCount) {
    //                         for (let i = startCount; i <= endCount; i++) {
    //                             pageList.push(i);
    //                         }
    //                     } else if (totalPages >= 1) {
    //                         for (let i = 1; i <= totalPages; i++) {
    //                             pageList.push(i);
    //                         }
    //                     }
    //                     return pageList;
    //                 }
    //             });
    //             setLoading(false);
    //             delete new_params["page"];
    //             delete new_params["ordering"];
    //             setParams(new_params);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         });
    // }, [page, sort, filter, component, refresh, isMobile]);

    let timer = null;
    const handleSearch = e => {
        addURLParam(window, "search", e.target.value);
        clearTimeout(timer);
        timer = setTimeout(function () {
            setFilter({
                ...filter,
                search: e.target.value
            });
        }, 750);
    };

    //API call to get the total shipping chargeback amount
    // useEffect(() => {
    //     let new_params = getParams(filter, setClearAll);
    //     new_params = {
    //         ...new_params,
    //         page,
    //         ordering: sort
    //     };
    //     api.get("discount/total-chargeback-amount/", {
    //         params: new_params,
    //         cancelToken:cancelToken.current.token
    //     })
    //         .then(response => {
    //             setTotalChargebackAmount(response.data.results);
    //             setParams(new_params);
    //         })
    //         .catch(error => addToast(error.message, { appearance: "error" }));
    // }, [addToast, filter, page, selected, sort]);

    const handlePagination = page => {
        setPage(page);
    };

    const urlSearchParams = useLocation().search;
    React.useMemo(() => {
        if (filter.urlParamsProcessed === false) processURLSearchParams(new URLSearchParams(urlSearchParams), filter, setFilter);
    }, [urlSearchParams]);

    const handleRefresh = () => {
        setRefresh(!refresh);
    };

    const { user, isAuthentication } = useContext(AuthContext);

    const refChargebacksCheckbox = useRef(false);

    const handleChargebacksCheckbox = e => {
        refChargebacksCheckbox.current = e.target.checked;
        let checkboxes = document.querySelectorAll(".chargebackCheckbox");
        let items = [];
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = e.target.checked;
            if (e.target.checked === true) {
                items.push({
                    id: parseInt(checkboxes[i].value),
                    title: checkboxes[i].name
                });
            }
        }
        setClearAll(true);
        setSelected(items);
    };

    const handleClearAll = () => {
        setClearAll(false);
        setSelected([]);
        removeAllURLParams();
        clearFilterState();
    };

    if (isAuthentication && !user.isSupplier && !user.isAdmin) {
        window.location.href = window.env.APP_URL;
    }

    /**
     * Function for handling Export Functionality
     */
    const handleExportFulfillments = () => {
        setExportButton({
            label: "Exporting...",
            disabled: true,
        });

        let new_params = params;
        new_params.fulfillment_order_ids = selected.map((item) => item.id);

        api
            .get("discount-code-chargeback/export/", {
                params: new_params,
            })
            .then((response) => response.data)
            .then((response) => {
                const success = response?.success | false;

                if (success) {
                    addToast(response.message, {
                        appearance: "success",
                        autoDismiss: false,
                    });
                } else {
                    const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
                    const filename = "discount_code_chargebacks_" + timestamp + ".csv";
                    fileDownload(response, filename);
                }

                setExportButton({
                    label: "Export",
                    disabled: false,
                });
            });
    };

    /**
     * Handler to open update chargeback status
     * @param {*} updateChargebackRecords
     */
    const handleUpdateChargebackStatusModal = updateChargebackRecords => {
        setOrderId(updateChargebackRecords);
        setOpenModal({ updateChargebackRecords: true });
    };
    const searchInput = document.getElementById("discountCodeChargebackSearch")

    /**
     * Clearing all the selected orders
     * @param {*}
     */
    const onCompleteStatusUpdate = () => {
        setSelected([]);
    };
    return (
        <>
            {isAuthentication ? (
                <Container fluid>
                    <ChargebackStatusModal
                        orders={orderId}
                        onSubmitURL="discount/update/chargeback-status"
                        isFromDiscountCode={true}
                        selectAllShippingChargebacksInThisView={selectAllDiscountCodeChargebacksInThisView}
                        filter={filter}
                        onComplete={onCompleteStatusUpdate}
                        isOpen={openModal.updateChargebackRecords}
                        shippingChargebacksCount={chargebackCount}
                        onExit={() => setOpenModal({ updateChargebackRecords: !openModal.updateChargebackRecords })}
                    />
                    <div className={"pt-7"}>
                        {isAuthentication && (
                            <Row className={"mb-2"}>
                                <Col xs={8} lg={2} className={"text-left"}>
                                    {/* 7278215305 - increase font size */}
                                    <input
                                        type="text"
                                        className={"button-font-size form-control form-control-sm"}
                                        placeholder={"Order ID"}
                                        id="discountCodeChargebackSearch"
                                        defaultValue={filter.search}
                                        onChange={handleSearch}
                                        newparams={""}
                                    />
                                </Col>
                                <Col className={"text-right"}>
                                    <div className={"d-none d-lg-inline-block mr-2"}>
                                        {/* 7278215305 - increase font size */}
                                        <button className="button-font-size btn btn-neutral btn-sm p-9px" onClick={handleRefresh}>
                                            <i className="fas fa-sync-alt"></i>
                                        </button>
                                    </div>
                                    <Filter
                                        name={"Brand"}
                                        prefix={"discountCodeBrand"}
                                        onChange={e => onFilterChange(e, "discountCodeBrand", filter, setFilter)}
                                        newparams={params}
                                        selected={filter.discountCodeBrand}
                                        url={"/discount/filter/brand/"}
                                        onClear={() => clearFilter("discountCodeBrand", setFilter, setClearAll)}
                                    />

                                    <Filter
                                        name={"Billing Status"}
                                        prefix={"discountCodeBillingStatus"}
                                        onChange={e => onFilterChange(e, "discountCodeBillingStatus", filter, setFilter)}
                                        newparams={params}
                                        selected={filter.discountCodeBillingStatus}
                                        inputSearch={false}
                                        url={"discount/filter/discount-code-billing-status/"}
                                        onClear={() => clearFilter("discountCodeBillingStatus", setFilter, setClearAll)}
                                    />

                                    <Filter
                                        name={"Sales Channel"}
                                        prefix={"sales_channel"}
                                        onChange={e => onFilterChange(e, "sales_channel", filter, setFilter)}
                                        newparams={params}
                                        selected={filter.channels}
                                        url={"order/filter/channel/"}
                                        onClear={() => clearFilter("sales_channel", setFilter, setClearAll)}
                                    />

                                    <Filter
                                        name={"Discount Type"}
                                        prefix={"discountCodeType"}
                                        onChange={e => onFilterChange(e, "discountCodeType", filter, setFilter)}
                                        newparams={params}
                                        selected={filter.discountCodeType}
                                        url={"discount/filter/discount-code-type/"}
                                        onClear={() => clearFilter("discountCodeType", setFilter, setClearAll)}
                                    />

                                    <Filter
                                        name={"Invoice Number"}
                                        prefix={"invoiceNumbers"}
                                        onChange={e => onFilterChange(e, "invoiceNumbers", filter, setFilter)}
                                        newparams={params}
                                        selected={filter.invoiceNumbers}
                                        url={"discount/filter/invoice-number/"}
                                        onClear={() => clearFilter("invoiceNumbers", setFilter, setClearAll)}
                                    />

                                    <Filter
                                        name={"Discount Code"}
                                        prefix={"discount_code"}
                                        onChange={e => onFilterChange(e, "discount_code", filter, setFilter)}
                                        newparams={params}
                                        selected={filter.discountCodes}
                                        url={"/order/filter/discount-code/"}
                                        onClear={() => clearFilter("discount_code", setFilter, setClearAll)}
                                    />

                                    {user?.isAdmin && (
                                        <Filter
                                            name={"Billable User"}
                                            prefix={"billableUser"}
                                            onChange={(e) => onFilterChange(e, 'billableUser', filter, setFilter)}
                                            newparams={params}
                                            selected={filter.billableUser}
                                            url={"discount/filter/discount-code-billable-user/"}
                                            onClear={() => clearFilter('billableUser', setFilter, setClearAll)}
                                        />
                                        )
                                    }

                                       <Filter
                                            name={'Store'}
                                            prefix={'store'}
                                            onChange={(e) => onFilterChange(e, 'store', filter, setFilter)}
                                            newparams={params}
                                            selected={filter.store}
                                            url={'order/shipping-chargebacks/filter/store'}
                                            onClear={() => clearFilter('store', setFilter, setClearAll)}
                                        />

                                    <DateRange onCallback={handleDateRange} button_name="Order Date"></DateRange>
                                    {/* TODO: Implement Chargeback Date Filter in next phase  */}
                                    {/* BCD-4294 Discount Code Chargeback Created At */}
                                    <DateRange onCallback={handleChargebacksDateRange} button_name="Chargeback Created Date"></DateRange>
                                    {/* 7278215305 - increase font size */}
                                    <Button
                                        color={"primary"}
                                        size={"sm"}
                                        disabled={exportButton.disabled}
                                        onClick={handleExportFulfillments}
                                        className='button-font-size p-9px'
                                    >
                                        <i className="fas fa-file-csv"></i> {exportButton.label}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            {isAuthentication && (
                                <div className="col">
                                    <Card className="shadow ">
                                        <CardHeader className="border-0">
                                            <Row className="d-flex justify-content-between align-items-center p-1">
                                                <div className="d-flex">
                                                    <h3 className="mb-0">
                                                        Discount Code Chargebacks <small>({chargebackCount})</small>
                                                    </h3>

                                                    {clearall > 0 && (
                                                        <a
                                                            href={void 0}
                                                            className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                                                            onClick={handleClearAll}
                                                            id="clear_all_filters">
                                                            <i className="fas fa-times-circle"></i> Clear All filters
                                                        </a>
                                                    )}

                                                    {selected.length === 50 && (
                                                        <Button
                                                            className="ml-2 bg-transparent border-none shadow-none text-primary"
                                                            onClick={() => {
                                                                setselectAllDiscountCodeChargebacksInThisView(!selectAllDiscountCodeChargebacksInThisView);
                                                            }}
                                                            size="sm">
                                                            <u>
                                                                {" "}
                                                                {!selectAllDiscountCodeChargebacksInThisView
                                                                    ? " Select all Discount code chargebacks in this view "
                                                                    : " All Discount code chargebacks in this view are selected "}{" "}
                                                            </u>
                                                        </Button>
                                                    )}
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    {selected.length > 0 && user.isAdmin && (
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                className="btn text-primary"
                                                                href="#"
                                                                role="button"
                                                                size="sm"
                                                                color="white"
                                                                onClick={e => e.preventDefault()}>
                                                                <i className="fas fa-cogs" /> Actions
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                                <DropdownItem href="#" onClick={e => handleUpdateChargebackStatusModal(selected)}>
                                                                    <i className="fas fa-envelope" /> Update Chargeback Status
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    )}
                                                    <h5 className="mb-0">Total Chargeback Amount: ${totalChargebackAmount}</h5>
                                                </div>
                                            </Row>

                                            <Row>
                                                <Col sm="12">
                                                    <BadgePillFilter
                                                        data={filter}
                                                        selected={selected}
                                                        onClick={(e) => { removeBadge(e, filter, setFilter, selected, setSelected, () => { }, searchInput) }
                                                    }
                                                    />
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        {isLoading ? (
                                            <div className="text-center mt-3 mb-3">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <ChargebacksTable
                                                    chargebacks={chargebacks || []}
                                                    handleSelectAllCheckbox={handleChargebacksCheckbox}
                                                    selected={selected}
                                                    handleCheckbox={handleCheckbox}
                                                    handleUpdateChargebackStatusModal={handleUpdateChargebackStatusModal}
                                                    sort={sort}
                                                    setSort={setSort}
                                                    setSortOrder={setSortOrder}
                                                    sortOrder={sortOrder}
                                                    user={user}
                                                    refChargebacksCheckbox={refChargebacksCheckbox}
                                                />
                                                <PagePagination
                                                    pagination={pagination}
                                                    handlePagination={handlePagination}
                                                    page={page}
                                                    totalCount={chargebackCount}
                                                />
                                            </>
                                        )}
                                    </Card>
                                </div>
                            )}
                        </Row>
                    </div>
                </Container>
            ) : null}
        </>
    );
};

export default DiscountCodeChargebacks;
