// 6322422002- Store Details Migration
import React, { useContext, useEffect, useRef, useState, useCallback } from "react";
import { Card, CardFooter, CardHeader, Col, Container, Row, Table, Input, UncontrolledTooltip } from "reactstrap";
import { api } from "services/api";
import Moment from "react-moment";
import { removeAllURLParams, addURLParam } from "utils/urls";
import Select from "react-select";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";
import { useLocation, NavLink } from "react-router-dom";
import StoreWebhooks from "./store-webhooks";
import "./storeDetails.css";
import { useHistory } from "react-router-dom";
import "../../utils/commonStyles.css";

const StoreDetails = () => {
    const { addToast } = useToasts();
    const history = useHistory();
    const searchParams = new URLSearchParams(window.location.search);
    const storeId = searchParams.get("storeId");
    const [storeDetailsInfo, setStoreDetailsInfo] = useState({}); //main state
    //Dropdown Options States
    const [fulfillmentStateList, setFulfillmentStateList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [brandList, setBrandList] = useState([]);

    // Dropdown value states
    const [storeGroupAssociated, setStoreGroupAssociated] = useState([]);
    const [storeBrands, setStoreBrands] = useState([]);
    const [storeFulfillmentStates, setStoreFulfillmentStates] = useState([]);

    //Section Lists
    const [storeKeys, setStoreKeys] = useState([]); //Store key section(2)
    const [storeLocations, setStoreLocations] = useState([]); //Store Location section state(3)
    const [storeAccessScopes, setStoreAccessScopes] = useState([]); //Store Access Scopes section state(5)
    const [storeWebhooks, setStoreWebHooks] = useState([]); //Store Webhooks section state(4)

    //Section 1 States:
    const [section1Details, setSection1Details] = useState({
        name: "",
        code: "",
        shopifDomain: "",
        vanityURL: "",
        isActive: true,
        delayedEmails: true,
        logoURL: "",
        wmsStartDate: new Date(),
        integrationStartDate: new Date()
    });

    // const [flag, setFlag] = useState(false);
    const [addKeyComponents, setAddKeyComponents] = useState([]);

    const [error, setError] = useState(false);
    const [syncProductsKey, setSyncProductsKey] = useState(null);

    const storeFulfillmentStatesIds = storeFulfillmentStates.map(item => item.value);
    const storeBrandsIds = storeBrands.map(item => item.value);
    const storeGroupAssociatedIds = storeGroupAssociated?.value;

    //Default Radiobutton
    const [selectedDefault, setSelectedDefault] = useState(null);
    // 6639158808- Allow buy button toggle on store details page
    const [toggleStates, setToggleStates] = useState({
        buyButton: false
    });

    const [showBuyButton, setShowBuyButton] = useState();
    const [activateBuyButton, setActivateBuyButton] = useState();

    const [salesChannels, setSalesChannels] = useState([]);

    // Filter only the updated sales channels
    const updatedChannels = salesChannels.filter(channel => channel.isUpdated);

    // 7105856900 - Remove shopify_id from sales_channel while sending to api payload because shopify_id is non editable field
    const sanitizedSalesChannels = updatedChannels?.map(channel => {
        const { shopify_id, isUpdated, ...rest } = channel;
        return rest;
    });

    /**
     * Payload for PUT request for main form
     */
    const payloadData = {
        name: section1Details?.name,
        code: section1Details?.code,
        domain: section1Details?.shopifDomain,
        vanity_url: section1Details?.vanityURL || null,
        is_active: section1Details?.isActive || null,
        delayed_emails: section1Details?.delayedEmails || null,
        logo_url: section1Details?.logoURL || null,
        wms_start_date: section1Details?.wmsStartDate,
        integration_start_date: section1Details?.integrationStartDate,
        group: storeGroupAssociatedIds || null,
        store_fulfillment_states: storeFulfillmentStatesIds,
        store_brands: storeBrandsIds,
        activate_buy_button: activateBuyButton,
        sales_channels: sanitizedSalesChannels,
    };
    console.log('payloadData: ', payloadData);

    /**
     * Handler for Submitting the form
     * @param {*} e
     */
    const handleSubmit = e => {
        e.preventDefault();

        if (section1Details?.name === "") {
            setError(true);
            addToast("Please fill the name.", { appearance: "error", autoDismiss: true });
        } else if (section1Details?.code === "") {
            setError(true);
            addToast("Please fill the code.", { appearance: "error", autoDismiss: true });
        } else if (section1Details?.shopifDomain === "") {
            setError(true);
            addToast("Please fill the shopify domain.", { appearance: "error", autoDismiss: true });
        } else if (section1Details?.integrationStartDate === null) {
            setError(true);
            addToast("Please fill the integration start date.", { appearance: "error", autoDismiss: true });
        }

        if (
            section1Details?.name !== "" &&
            section1Details?.code !== "" &&
            section1Details?.shopifDomain !== "" &&
            section1Details?.integrationStartDate !== null
        ) {
            api.put(`store/${storeId}/`, payloadData)
                .then(response => {
                    if (response?.data?.success === true) addToast("Saved Successfully", { appearance: "success", autoDismiss: true });
                    else if (response?.data?.success === false) addToast(`${response?.data?.message}`, { appearance: "error", autoDismiss: true });
                })
                .catch(error => {
                    if (error.response && error.response.status === 400) {
                        addToast("This code is already used for another store, Please try with another code", { appearance: "error", autoDismiss: true });
                    } else addToast(error.message, { appearance: "error", autoDismiss: true });
                });
        }
    };

    const fetchStoreDetails = () => {
        api.get(`store/${storeId}`, {})
            .then(response => {
                //main state
                setStoreDetailsInfo(response?.data);
                //Dropdown Options States
                setFulfillmentStateList(response?.data?.fulfillment_states);
                setGroupList(response?.data?.store_groups_list);
                setBrandList(response?.data?.brands_list);

                // Dropdown value states
                setStoreGroupAssociated(response?.data?.store_groups_associated);
                setStoreBrands(response?.data?.store_brands);
                setStoreFulfillmentStates(response?.data?.store_fulfillment_states);

                //Section Lists
                setStoreKeys(response?.data?.store_keys?.length > 0 ? response?.data?.store_keys : []); //Store key section(2)
                setStoreLocations(response?.data?.store_locations); //Store Location section state(3)
                setStoreAccessScopes(response?.data?.store_access_scopes); //Store Access Scopes section state(5)
                setStoreWebHooks(response?.data?.store_webhooks); //Store Webhooks section state(4)

                setShowBuyButton(response?.data?.show_buy_button);
                setActivateBuyButton(response?.data?.activate_buy_button);
                // 7105856900 - Updating sales_channel for store details page
                const channelsWithFlags = response?.data?.sales_channels?.map(channel => ({
                    ...channel,
                    isUpdated: false,
                }));
                setSalesChannels(channelsWithFlags || []);

                //Section 1 state
                setSection1Details(prevState => ({
                    ...prevState,
                    name: response?.data["name"],
                    code: response?.data["code"],
                    shopifDomain: response?.data["domain"],
                    vanityURL: response?.data["vanity_url"],
                    isActive: response?.data["is_active"],
                    delayedEmails: response?.data["delayed_emails"],
                    logoURL: response?.data["logo_url"],
                    wmsStartDate: moment(response?.data["wms_start_date"]).isValid() ? moment(response?.data["wms_start_date"]).format("YYYY-MM-DD") : null,
                    integrationStartDate: response?.data["integration_start_date"],
                    group: response?.data?.store_groups_associated?.value
                }));
            })
            .catch(err => {
                if (err.response.status === 403) {
                    addToast(err.response.data.detail, { appearance: "error", autoDismiss: true });
                    history.push("dashboard");
                }
                console.log(err);
            });
    };
    useEffect(() => {
        fetchStoreDetails();
    }, []);
    {
        /** Handler for Group dropdown  */
    }
    const handleStoreGroupAssociated = newValue => {
        setStoreGroupAssociated(newValue);
    };

    {
        /**Handler for Brands dropdown */
    }
    const handleStoreBrands = newValue => {
        setStoreBrands(newValue);
    };

    {
        /**Handler fo Fulfillment states dropdown */
    }
    const handleStoreFulfillmentStates = newValue => {
        setStoreFulfillmentStates(newValue);
    };

    /**
     * Function for handling change of the Input
     */
    const handleChange = useCallback(
        e => {
            setSection1Details({ ...section1Details, [e.target.id]: e.target.value });
        },
        [section1Details]
    );

    /**
     * Function for handling change of the delayed email checkbox
     */
    const handleChangeChecked = useCallback(
        e => {
            setSection1Details({ ...section1Details, [e.target.id]: e.target.checked });
        },
        [section1Details]
    );

    /**
     * Function for handling default radio buttons
     * @param {*} storeLocationId
     */
    const handleDefaultRadio = storeLocationId => {
        setSelectedDefault(storeLocationId); // This updates the state asynchronously.

        const payloadCheckbox = {
            id: storeLocationId // Use directly the storeLocationId
        };

        // Perform the API call with the updated ID.
        api.put("store/location/control/", payloadCheckbox)
            .then(response => {
                addToast("Saved Successfully", { appearance: "success", autoDismiss: true });
            })
            .catch(error => addToast(error.message, { appearance: "error", autoDismiss: true }));
    };

    // Effect to set the default based on the storeLocations prop
    useEffect(() => {
        // Find the store location that is marked as default
        const defaultLocation = storeLocations.find(loc => loc.is_default);
        // If found, set it as the selected default
        if (defaultLocation) {
            setSelectedDefault(defaultLocation.id);
        }
    }, [storeLocations]); // Depend on storeLocations so it updates if the prop changes

    /**
     * Function to handle Store webhooks delete/trash functionality
     * @param {*} id
     */
    const handleStoreWebhooktrash = id => {
        api.delete("store/webhook/control/", { data: { id: id } })
            .then(response => {
                if (response?.data?.success === false) addToast(`${response?.data?.message}`, { appearance: "error", autoDismiss: true });
                else addToast("Deleted Successfully", { appearance: "success", autoDismiss: true });
                fetchStoreDetails();
            })
            .catch(error => addToast(error.message, { appearance: "error" }));
    };

    /**
     * Function for handling Store Webhook Create/Enable functionalities
     * @param {*} id
     */
    const handleStoreWebhookCreate = id => {
        const payloadWebhook = { id: id };
        api.put("store/webhook/control/", payloadWebhook)
            .then(response => {
                if (response?.data?.success === false) addToast(`${response?.data?.message}`, { appearance: "error", autoDismiss: true });
                else addToast("Enabled Successfully", { appearance: "success", autoDismiss: true });
                fetchStoreDetails();
            })
            .catch(error => addToast(error.message, { appearance: "error" }));
    };

    /**
     * Function for handling Sync Products
     */
    const handleSyncProducts = () => {
        api.get(`store/sync-products/${storeId}`, {})
            .then(response => {
                setSyncProductsKey(response?.data?.message);
            })
            .catch(err => {
                console.log(err);
            });
    };

    /**
     * Handle Store Delete Key
     * @param {*} storeId
     */
    const handleDeleteStoreKey = storeId => {
        api.delete(`store/store-key/delete/${storeId}`)
            .then(response => {
                addToast("Deleted Successfully", { appearance: "success", autoDismiss: true });
            })
            .catch(error => addToast(error.message, { appearance: "error" }));
    };

    // 6639158808- Allow buy button toggle on store details page
    // Toggle handler
    const handleToggleChange = toggleName => e => {
        const newState = e.target.checked ? true : false;
        setActivateBuyButton(newState);
        setToggleStates(prevStates => ({
            ...prevStates,
            [toggleName]: newState
        }));
    };

    const isActiveOptions = [
        { value: true, label: "Yes" },
        { value: false, label: "No" }
    ];

    const handleIsActiveChange = option => {
        setSection1Details(prevState => ({ ...prevState, isActive: option.value }));
    };

    // 7105856900 - Handle function for sales channel name and slug update
    const handleSalesChannelChange = (index, field, value) => {
        const updatedChannels = [...salesChannels];
        updatedChannels[index] = { ...updatedChannels[index], [field]: value, isUpdated: true };
        setSalesChannels(updatedChannels);
    };

    return (
        <Container fluid>
            <div className={"pt-7"} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <>
                    <h2 className="mb-0">Change Store</h2>
                    <div style={{ width: 1110 }}>
                        <Card className="shadow mt-3" style={{}}>
                            <CardHeader className="border-0">
                                <div>
                                    Name *
                                    <Input id="name" value={section1Details.name} className="input-group input-group-alternative" onChange={handleChange} />
                                    {error === true && section1Details?.name === "" && <div style={{ color: "red" }}>{"* Please fill name"}</div>}
                                </div>
                                <div>
                                    Code *
                                    <Input id="code" value={section1Details.code} className="input-group input-group-alternative" onChange={handleChange} />
                                    {error === true && section1Details?.code === "" && <div style={{ color: "red" }}>{"* Please fill code"}</div>}
                                </div>
                                <div>
                                    Shopify Domain *
                                    <Input
                                        id="shopifDomain"
                                        value={section1Details.shopifDomain}
                                        className="input-group input-group-alternative"
                                        onChange={handleChange}
                                    />
                                    {error === true && section1Details?.shopifDomain === "" && (
                                        <div style={{ color: "red" }}>{"* Please fill shopify Domain"}</div>
                                    )}
                                </div>
                                <div>
                                    Vanity URL
                                    <Input
                                        id="vanityURL"
                                        value={section1Details.vanityURL}
                                        className="input-group input-group-alternative"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div>
                                    Is Active
                                    <Select
                                        options={isActiveOptions}
                                        value={isActiveOptions.find(option => option.value === section1Details.isActive)}
                                        onChange={handleIsActiveChange}
                                    />
                                </div>
                                <div>
                                    Brands
                                    <Select options={brandList} isMulti value={storeBrands} onChange={handleStoreBrands} />
                                </div>
                                <br />
                                <div className="ml-3">
                                    <Input type="checkbox" id="delayedEmails" checked={section1Details.delayedEmails} onChange={handleChangeChecked} />
                                    Delayed Emails
                                </div>
                                <br />
                                <div>
                                    Local Fulfillment State
                                    <Select options={fulfillmentStateList} isMulti value={storeFulfillmentStates} onChange={handleStoreFulfillmentStates} />
                                </div>
                                <br />
                                Logo URL
                                <Input
                                    id="logoURL"
                                    type="textarea"
                                    rows="5"
                                    className="input-group input-group-alternative"
                                    value={section1Details.logoURL}
                                    onChange={handleChange}
                                />{" "}
                                <div>
                                    WMS Start Date
                                    <Input
                                        id="wmsStartDate"
                                        value={section1Details.wmsStartDate}
                                        type="date"
                                        className="input-group input-group-alternative"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div>
                                    Integration Start Date *
                                    <Input
                                        id="integrationStartDate"
                                        value={section1Details.integrationStartDate}
                                        type="date"
                                        className="input-group input-group-alternative"
                                        onChange={handleChange}
                                    />
                                    {error === true && section1Details?.integrationStartDate === null && (
                                        <div style={{ color: "red" }}>{"* Please fill integration start date"}</div>
                                    )}
                                </div>
                                <div>
                                    Group
                                    <Select id="group" options={groupList} value={storeGroupAssociated} onChange={handleStoreGroupAssociated} />
                                </div>
                            </CardHeader>
                        </Card>

                        <Card className="shadow mt-3" style={{}}>
                            <CardHeader className="border-0">
                                <h3>Sales Channels</h3>
                                <hr />
                                <div className="table-responsive">
                                    <table className="table mb-3">
                                        <thead className="thead-light">
                                            <tr>
                                                <th className="equal-width">Channel Name</th>
                                                <th className="equal-width text-center">Shopify ID</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {salesChannels?.map((channel, index) => (
                                                <tr key={index}>
                                                    <td className="equal-width">
                                                        <Input
                                                            type="text"
                                                            value={channel?.name || ''}
                                                            onChange={(e) => handleSalesChannelChange(index, 'name', e.target.value)}
                                                        />
                                                    </td>
                                                    <td className="equal-width text-center">{channel?.shopify_id || "-"}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </CardHeader>
                        </Card>

                        <Card className="shadow mt-3" style={{}}>
                            <CardHeader className="border-0">
                                {storeKeys?.length > 0 ? (
                                    storeKeys?.map(storeKey => (
                                        <>
                                            <h3>Store Keys</h3>
                                            <hr />
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <div
                                                    className="control-label text-sm"
                                                    style={{ display: "flex", flexDirection: "column", padding: "0px 10px" }}>
                                                    <>Key *</>
                                                    <>
                                                        <Input value={storeKey?.key} type="key" className="input-group input-group-alternative" />
                                                    </>
                                                </div>
                                                <div
                                                    className="control-label text-sm"
                                                    style={{ display: "flex", flexDirection: "column", padding: "0px 10px" }}>
                                                    <>Access Token *</>
                                                    <>
                                                        <Input
                                                            value={storeKey?.secret_key}
                                                            type="access token"
                                                            className="input-group input-group-alternative"
                                                        />
                                                    </>
                                                </div>
                                                <div
                                                    className="control-label text-sm"
                                                    style={{ display: "flex", flexDirection: "column", padding: "0px 10px" }}>
                                                    <>Storefront Access Token</>
                                                    <>
                                                        <Input
                                                            value={storeKey?.storefront_access_token}
                                                            type="storefront access token"
                                                            className="input-group input-group-alternative"
                                                        />
                                                    </>
                                                </div>
                                                <div
                                                    className="control-label text-sm"
                                                    style={{ display: "flex", flexDirection: "column", flex: 1, padding: "0px 10px" }}>
                                                    <div>App</div>
                                                    <>
                                                        <Select className="input-group input-group-alternative" value={storeKey?.app} isDisabled={true} />
                                                    </>
                                                </div>
                                                <div
                                                    className="control-label text-sm"
                                                    style={{ display: "flex", flexDirection: "column", flex: 1, padding: "0px 10px" }}>
                                                    <>Version *</>
                                                    <>
                                                        <Select className="input-group input-group-alternative" value={storeKey?.version} isDisabled={true} />
                                                    </>
                                                </div>
                                                <button className="btn btn-outline-danger btn-sm mr-0" onClick={() => handleDeleteStoreKey(storeKey?.id)}>
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </div>
                                            <div className="text-center"></div>
                                            <div>
                                                {/* If `syncProductsKey` is `null`, show the "Sync Products" button */}
                                                {syncProductsKey === null ? (
                                                    <button className="btn btn-sm btn-outline-primary" onClick={handleSyncProducts}>
                                                        <i className="fas fa-sync"></i> Sync Products
                                                    </button>
                                                ) : (
                                                    <button className="btn btn-sm btn-outline-primary" disabled={true}>
                                                        <i className="spinner-border spinner-border-sm"></i> Synchronizing
                                                    </button>
                                                )}
                                            </div>
                                        </>
                                    ))
                                ) : (
                                    <>
                                        <h3>Store Keys</h3>
                                        <hr />
                                        <>
                                            <button
                                                className="text-center"
                                                // onClick={handleNewStoreKey}
                                            >
                                                {/* 7278215305 - increase font size */}
                                                <button className="button-font-size btn btn-sm btn-outline-primary">Add key</button>
                                            </button>
                                        </>
                                    </>
                                )}
                            </CardHeader>
                        </Card>
                        <Card className="shadow mt-3" style={{}}>
                            <CardHeader className="border-0">
                                <h3>Store Locations</h3>
                                <hr />
                                <div className="table-responsive">
                                    <table className="table mb-3">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Name</th>
                                                <th>Shopify Id</th>
                                                <th>Created At</th>
                                                <th>Is Active</th>
                                                <th>Default</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {storeLocations?.map(storeLocation => (
                                                <tr>
                                                    <td>{storeLocation?.name}</td>
                                                    <td>{storeLocation?.shopify_id}</td>
                                                    <td>
                                                        <Moment format="MM/D/YYYY" withTitle>
                                                            {storeLocation?.created_at}
                                                        </Moment>
                                                    </td>
                                                    <td>{storeLocation?.active === true ? <i className="fas fa-check-circle text-success" /> : ""}</td>
                                                    <td>
                                                        <input
                                                            type="radio"
                                                            checked={selectedDefault === storeLocation.id}
                                                            onChange={() => handleDefaultRadio(storeLocation.id)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </CardHeader>
                        </Card>
                        <StoreWebhooks
                            storeWebhooks={storeWebhooks}
                            handleStoreWebhookCreate={handleStoreWebhookCreate}
                            handleStoreWebhooktrash={handleStoreWebhooktrash}
                        />
                        {/* 6639158808- Allow buy button toggle on store details page */}
                        {showBuyButton === true ? (
                            <div className="enable-buy-button-container">
                                <form className="enable-buy-button-header">
                                    <div>
                                        <h3>Enable Buy Buttons</h3>
                                    </div>
                                    <label className="store-details-switch">
                                        <input type="checkbox" onChange={handleToggleChange("buyButton")} checked={activateBuyButton} />
                                        <span className="store-details-slider round">
                                            <span className="toggle-store-details-text true-text">On</span>
                                            <span className="toggle-store-details-text false-text">Off</span>
                                        </span>
                                    </label>
                                </form>
                            </div>
                        ) : (
                            <></>
                        )}
                        <Card className="shadow mt-3" style={{}}>
                            <CardHeader className="border-0">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h3>Store Access Scopes</h3>
                                    <span>
                                        All required access scopes are granted
                                        {storeAccessScopes?.length > 0 ? (
                                            <>
                                                {"\u00A0"}
                                                <i className="fa fa-times-circle text-danger" />
                                            </>
                                        ) : (
                                            <>
                                                {"\u00A0"}
                                                <i className="fa fa-check-circle text-success" />
                                            </>
                                        )}
                                    </span>
                                </div>
                                {storeAccessScopes?.length > 0 ? (
                                    <div className="alert alert-danger">
                                        {storeAccessScopes?.map((storeAccessScope, index) => (
                                            <div key={index}>{storeAccessScope}</div>
                                        ))}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </CardHeader>
                        </Card>
                        <hr />
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <NavLink to={"/store"} style={{ cursor: "pointer" }}>
                                <a className="btn btn-lg">
                                    <i className="fas fa-arrow-left"> Back</i>
                                </a>
                            </NavLink>
                            <button className="btn btn-success btn-lg" onClick={handleSubmit}>
                                Submit <i className="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </>
            </div>
        </Container>
    );
};
export default StoreDetails;
