import React, { useState, useEffect, useCallback } from "react";
import "./UserForm.css";
import { Container, Input, Spinner, Table } from "reactstrap";
import { CustomDropdown } from "../../components/CustomDropdown/CustomDropdown";
import { useToasts } from "react-toast-notifications";
import { FaTrash } from "react-icons/fa";
import { stateListOptions } from "../../utils/constants";
import { api } from "services/api";
import { useHistory } from "react-router-dom";
import { useMemo } from "react";
import Select from "react-select";

import AsyncCreatableSelect from 'react-select/async-creatable';
import FulfillmentStatesTable from "./FulfillmentStatesTable";
import "../../utils/commonStyles.css"
const useDebouncedSearch = (searchTerm, delay = 300) => {
    const [debouncedValue, setDebouncedValue] = useState(searchTerm);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(searchTerm);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm, delay]);
    return debouncedValue;
};

// 1st Section User Details
const FormInput = ({ id, label, type, value, onChange,disabled=false }) => {
    const [emailError, setEmailError] = useState("");
    const inputStyle = id === "email" ? { paddingLeft: "40px", border: "0" } : { border: "0" };
    const handlePaste = e => {
        const pastedValue = e.clipboardData.getData("text/plain");
        console.log("pastedValue: ", pastedValue);

        if (id === "email") {
            // Regular expression for validating email address
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmail = emailRegex.test(pastedValue);
            console.log("isValidEmail: ", isValidEmail);
            // Check if the pasted value matches the email format
            if (!isValidEmail) {
                setEmailError("Please enter a valid email address. Ex: test@example.com");
            } else {
                // Clear any existing email error
                setEmailError("");
            }
        }
    };
    return (
        <div className="input-group">
            <label htmlFor={id}>{label}</label>
            <div className="input-container">
                {id === "email" ? (
                    <input
                        type="email"
                        id={id}
                        placeholder={label}
                        onInput={e => {
                            const inputValue = e.target.value;
                            if (inputValue.trim() === "") {
                                // Clear any existing email error if the input is empty
                                setEmailError("");
                            } else {
                                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                const isValidEmail = emailRegex.test(inputValue);
                                if (!isValidEmail) {
                                    setEmailError("Please enter a valid email address. Ex: test@example.com");
                                } else {
                                    setEmailError("");
                                }
                            }
                        }}
                        onPaste={handlePaste}
                        value={value}
                        onChange={onChange}
                        style={inputStyle}
                    />
                ) : (
                    <input type={type} id={id} placeholder={label} value={value} onChange={onChange} style={inputStyle} disabled={disabled}/>
                )}

                {id === "email" && <i className="fas fa-envelope input-icon" />}
            </div>
            {emailError && <span className="error-message">{emailError}</span>}
        </div>
    );
};

// 2nd Section Contact Form
const RadioButton = ({ label, value, checkedValue, onChange }) => (
    <label>
        <input type="radio" name="contactMethod" value={value} checked={checkedValue === value} onChange={onChange} />
        <span className="contact-label">{label}</span>
    </label>
);

// 3rd Section Invite Link
const InviteLink = ({ inviteType, path, copyInviteToClipboard }) => (
    <label>
        <span>Invite a {inviteType} :</span>
        <span className="invite-link">https://app1.checkout.flaviar.com/register/{path}</span>
        <i className="fas fa-copy pl-1 pr-1" data-toggle="tooltip" onClick={() => copyInviteToClipboard(path)} style={{ cursor: "pointer" }}></i>
    </label>
);

const CreateUser = () => {
    const { addToast } = useToasts();
    const history = useHistory();
    const [imageUrl, setImageUrl] = useState(null);
    const [showImg, setShowImg] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [removeClicked, setRemoveClicked] = useState(false);
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const fulfillmentStatesRole = ["retailer","manager", "master"]
    // 1st Section User Form Fields
    const [formData, setFormData] = useState({
        email: "",
        firstName: "",
        lastName: "",
        telephone: "",
        company: ""
    });
    // 2nd Section Contact Me Fields
    const [contactMethod, setContactMethod] = useState("");
    //Insights Profile State
    const [insightsProfile, setInsightsProfile] = useState("");
    // 4th Section Permissions Fields
    const [formState, setFormState] = useState({
        userType: "",
        selectedStates: [{ value: "__all__", label: "All States" }],
        selectedBrands: [{ value: "no", label: "No Brands" }],
        selectedSupplierBrands: [],
        selectedRetailerInventory: [],
        selectedFulfillmentStates: [],
        fulfillmentStates:[],
        all_fullfillment_Active:false,
        all_tax_Active:false,
        userPermissions: [],
        brandsList: [],
        userTypeList: [],
        retailerInventoryLocations: [],
        newlyCreatedBrands: [],
    });
    const [documentRows, setDocumentRows] = useState([{ id: 1, title: "", url: "" }]);
    const [toggleStates, setToggleStates] = useState({
        productPage: "off",
        barcartButton: "off",
        discountCodePage: "off",
        weeklyEmail: "off",
        dailyEmail: "off",
        sonicExport: "off",
        retailerBilling: "off",
        shipStationIntegration: "off",
        flaviarInsights: "off"
    });
    // 5th Section Discount Code Fields
    const [availableCodes, setAvailableCodes] = useState([]);
    const [selectedCodes, setSelectedCodes] = useState([]);
    const [selectedAvailable, setSelectedAvailable] = useState([]);
    const [selectedSelected, setSelectedSelected] = useState([]);
    const [searchAvaliableCode, setSearchAvailableCodes] = useState("");
    const [searchSelectedCode, setSearchSelectedCode] = useState("");
    // Validation States
    const [userTypeValid, setUserTypeValid] = useState(true);
    // const [stateIsValidPermission, setStateIsValidPermission] = useState(true);
    // const [brandIsValid, setBrandIsValid] = useState(true);
    const [nextId, setNextId] = useState(documentRows.length + 1);
    const contactOptions = [
        { label: "Email", value: "email" },
        { label: "Text", value: "text" },
        { label: "Call", value: "call" }
    ];
    const inviteTypes = [
        { label: "brand", path: "" },
        { label: "retailer", path: "retailer/" }
    ];
    const imageUploadRef = React.useRef();
    const [unsupportedFile, setUnsupportedFile] = useState(false);
    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FB00}-\u{1FBFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E6}-\u{1F1FF}\u{231A}-\u{231B}\u{23F0}-\u{23F3}\u{23F1}-\u{23F2}\u{23E9}-\u{23EF}\u{23F8}-\u{23FA}\u{25A0}-\u{25A1}\u{25AA}-\u{25AB}\u{25B6}\u{25C0}\u{25FB}-\u{25FE}\u{2B05}-\u{2B07}\u{2B1B}-\u{2B1C}\u{2B50}\u{2B55}\u{3030}\u{303D}\u{3297}\u{3299}\u{1F004}\u{1F0CF}\u{1F170}-\u{1F171}\u{1F17E}-\u{1F17F}\u{1F18E}\u{1F191}-\u{1F19A}\u{1F201}-\u{1F20F}\u{1F21A}\u{1F22F}\u{1F232}-\u{1F251}\u{1F300}-\u{1F321}\u{1F324}-\u{1F393}\u{1F396}-\u{1F397}\u{1F399}-\u{1F39B}\u{1F39E}-\u{1F3F0}\u{1F3F3}-\u{1F3F5}\u{1F3F7}-\u{1F4FD}\u{1F4FF}-\u{1F53D}\u{1F549}-\u{1F54E}\u{1F550}-\u{1F567}\u{1F56F}-\u{1F570}\u{1F573}-\u{1F57A}\u{1F587}\u{1F58A}-\u{1F58D}\u{1F590}\u{1F595}-\u{1F596}\u{1F5A4}-\u{1F5A5}\u{1F5A8}\u{1F5B1}-\u{1F5B2}\u{1F5BC}\u{1F5C2}-\u{1F5C4}\u{1F5D1}-\u{1F5D3}\u{1F5DC}-\u{1F5DE}\u{1F5E1}\u{1F5E3}\u{1F5E8}\u{1F5EF}\u{1F5F3}\u{1F5FA}-\u{1F5FF}\u{1F6C5}-\u{1F6D2}\u{1F6E0}-\u{1F6E5}\u{1F6E9}-\u{1F6EC}\u{1F6F0}-\u{1F6F3}\u{1F6F4}-\u{1F6F9}\u{1F7E0}-\u{1F7EB}\u{1F90D}-\u{1F93A}\u{1F93C}-\u{1F945}\u{1F947}-\u{1F9FF}\u{1FA70}-\u{1FA74}\u{1FA78}-\u{1FA7A}\u{1FA80}-\u{1FA86}\u{1FA90}-\u{1FAA8}\u{1FAB0}-\u{1FAB6}\u{1FAC0}-\u{1FAC2}\u{1FAD0}-\u{1FAD6}\u{1FB00}-\u{1FB02}\u{1FB38}-\u{1FB3C}\u{1FB70}-\u{1FB71}\u{0023}\u{002A}\u{20E3}\u{FE0F}\u{1F004}\u{1F0CF}\u{1F170}-\u{1F171}\u{1F17E}-\u{1F17F}\u{1F18E}\u{1F191}-\u{1F19A}\u{1F1E6}-\u{1F1FF}]/gu;
    useEffect(() => {
        // Scroll to the top of CreateUserPage component when it mounts
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {

        const fetchUserTypes = async () => {
            try {
                const response = await api.get("users/react/filter/roles/");
                let responseUserTypes = response?.data?.results.map(user => ({ value: user.id, label: user.name }));

                const defaultUserType = responseUserTypes.find(userType => userType.value === "supplier");
                setFormState(prevState => ({
                    ...prevState,
                    userTypeList: responseUserTypes,
                    userType: defaultUserType ? defaultUserType.value : "" // Set the default value if found
                }));
            } catch (error) {
                console.error("Error fetching brands:", error);
            }
        };
        const fetchAvaliableDiscountCodes = async () => {
            try {
                const response = await api.get("users/react/available-discountcode-list/");
                let responseAvaiableDiscountCodes = response?.data.map(code => ({ value: code.value, label: code.name }));
                setAvailableCodes(responseAvaiableDiscountCodes);
            } catch (error) {
                console.error("Error fetching avaliable discount code:", error);
            }
        };
        const fetchRetailerInventoryLocation = async () => {
            try {
                const response = await api.get("inventory-location-list/");
                let responseRetailerInventoryLocations = response?.data.map(location => ({value: location.id, label: location.name}))
                setFormState(prevState => ({
                    ...prevState,
                    retailerInventoryLocations: responseRetailerInventoryLocations
                }));
            }catch (error) {
                console.error("Error fetching retailer inventory location:", error);
            }
        };

        fetchUserTypes();
        fetchAvaliableDiscountCodes();
        fetchRetailerInventoryLocation();
    }, []);

    useEffect(() => {
        if (documentRows.length > 0) {
            const maxId = Math.max(...documentRows.map(row => row.id));
            setNextId(maxId + 1);
        }
    }, [documentRows]);

    const handleTitleChange = (value, id) => {
        const updatedRows = documentRows.map(row => (row.id === id ? { ...row, title: value.target.value } : row));
        setDocumentRows(updatedRows);
    };

    const handleUrlChange = (value, id) => {
        const updatedRows = documentRows.map(row => (row.id === id ? { ...row, url: value.target.value } : row));
        setDocumentRows(updatedRows);
    };

    const handleDeleteDocumentBtn = id => {
        // Delete the row as usual if there are more than one row
        setDocumentRows(prevRows => prevRows.filter(row => row.id !== id));
    };

    const handleAddAnother = () => {
        // Check if the last row in documentRows has both title and URL filled
        const lastRow = documentRows[documentRows.length - 1];
        if (lastRow && (!lastRow.title || !lastRow.url)) {
            // If the last row doesn't have both title and URL filled, alert the user or handle it as needed
            addToast("Please fill in the title and URL for the previous document.", { appearance: "warning", autoDismiss: true });
            return; // Stop execution
        }
        setDocumentRows([...documentRows, { id: nextId, title: "", url: "" }]);
        setNextId(nextId + 1);
    };

    // Toggle handler
    const handleToggleChange = toggleName => e => {
        const newState = e.target.checked ? "on" : "off";
        setToggleStates(prevStates => ({
            ...prevStates,
            [toggleName]: newState
        }));
    };

    // 1st Section User Form Handler Function
    // const handleChange = e => {
    //     setFormData({ ...formData, [e.target.id]: e.target.value });
    // };
    // Memoized event handlers

    const handleChange = useCallback(
        e => {
            setFormData({ ...formData, [e.target.id]: e.target.value });
        },
        [formData]
    );

    // 2nd Section Contact Form Handler Function
    const handleContactMethodChange = useCallback(e => {
        setContactMethod(e.target.value);
    }, []);
    const handleInsightsProfile = useCallback(e => {
        setInsightsProfile(e.target.value.replace(emojiRegex, ''));
    }, []);

    // 3rd Section Invite Links Handler Function
    const copyInviteToClipboard = url => {
        navigator.clipboard.writeText(url);
        addToast("Invite URL copied to clipboard!", {
            appearance: "success",
            autoDismiss: true
        });
    };

    // 4th Section Permissions Handler Function
    const handleUserTypeChange = newValue => {
        setFormState(prevState => ({
            ...prevState,
            userType: newValue
        }));
    };

    const handleStatesChange = newValues => {
        // Find if '__all__' is currently selected
        const isAllSelected = formState.selectedStates.some(state => state.value === "__all__");
        const isNoSelected = formState.selectedStates.some(brand => brand.value === "none");

        // Check if '__all__' is among the newly added values
        const isNewValueAll = newValues.some(value => value.value === "__all__");
        const isNewValueNo = newValues.some(value => value.value === "none");

        let updatedValues;

        if (isNoSelected && newValues.length > 1) {
            updatedValues = newValues.filter(value => value.value !== "none");
        } else if (!isNoSelected && isNewValueNo) {
            updatedValues = newValues.filter(value => value.value === "none");
        } else if (isAllSelected && newValues.length > 1) {
            // If '__all__' is currently selected and new selections are added, remove '__all__'
            updatedValues = newValues.filter(value => value.value !== "__all__");
        } else if (!isAllSelected && isNewValueAll) {
            // If '__all__' is not currently selected but is now added, keep only '__all__'
            updatedValues = newValues.filter(value => value.value === "__all__");
        } else {
            // In any other case, just use the new values as they are
            updatedValues = newValues;
        }

        console.log("updatedValues: ", updatedValues);
        setFormState(prevState => ({
            ...prevState,
            selectedStates: updatedValues
        }));
    };

    const handleFulfillmentStatesChange = newValues => {
        // Find if '__all__' is currently selected
        const isAllSelected = formState.selectedFulfillmentStates.some(state => state.value === "__all__");
        const isNoSelected = formState.selectedFulfillmentStates.some(state => state.value === "none");
        // Check if '__all__' is among the newly added values
        const isNewValueAll = newValues.some(value => value.value === "__all__");
        const isNewValueNo = newValues.some(value => value.value === "none");
        let updatedValues;
        if (isNoSelected && newValues.length > 1) {
            // If 'none' is currently selected and new selections are added, remove 'none'
            updatedValues = newValues.filter(value => value.value !== "none");
        } else if (!isNoSelected && isNewValueNo) {
            updatedValues = newValues.filter(value => value.value === "none");
        } else if (isAllSelected && newValues.length > 1) {
            // If '__all__' is currently selected and new selections are added, remove '__all__'
            updatedValues = newValues.filter(value => value.value !== "__all__");
        } else if (!isAllSelected && isNewValueAll) {
            // If '__all__' is not currently selected but is now added, keep only '__all__'
            updatedValues = newValues.filter(value => value.value === "__all__");
        } else {
            // In any other case, just use the new values as they are
            updatedValues = newValues;
        }

        console.log("updatedValues: ", updatedValues);
        setFormState(prevState => ({
            ...prevState,
            selectedFulfillmentStates: updatedValues
        }));
    };

    const handleRetailerInventoryChange = selectedOptions => {
        console.log("selectedOptions: ", selectedOptions);
        setFormState(prevState => ({
            ...prevState,
            selectedRetailerInventory: selectedOptions
        }));
    };

    const handleBrandsChange = newValues => {
        // Find if '__all__' is currently selected
        const isAllSelected = formState.selectedBrands.some(brand => brand.value === "__all__");
        const isNoSelected = formState.selectedBrands.some(brand => brand.value === "no");
        // Check if '__all__' is among the newly added values
        const isNewValueAll = newValues.some(value => value.value === "__all__");
        const isNewValueNo = newValues.some(value => value.value === "no");

        let updatedValues;
        if (isNoSelected && newValues.length > 1) {
            // If 'no' is currently selected and new selections are added, remove 'no'
            updatedValues = newValues.filter(value => value.value !== "no");
        } else if (!isNoSelected && isNewValueNo) {
            updatedValues = newValues.filter(value => value.value === "no");
        } else if (isAllSelected && newValues.length > 1) {
            // If '__all__' is currently selected and new selections are added, remove '__all__'
            updatedValues = newValues.filter(value => value.value !== "__all__");
        } else if (!isAllSelected && isNewValueAll) {
            // If '__all__' is not currently selected but is now added, keep only '__all__'
            updatedValues = [{ value: "__all__", label: "All Brands" }];
        } else {
            // In any other case, just use the new values as they are
            updatedValues = newValues;
        }

        setFormState(prevState => ({
            ...prevState,
            selectedBrands: updatedValues
        }));
    };

    const handleCreatableBrandsChange = (newValue, actionMeta) => {
        console.log(`Action: ${actionMeta.action}`);
        
        if (actionMeta.action === 'create-option') {
            handleCreateOption(newValue);
        } else {
            handleBrandsChange(newValue);
        }
    };

    // Handle the creation of a new option
    const handleCreateOption = (newValue) => {
        // Assume the last item in newValue is the newly created option
        const newOption = newValue[newValue.length - 1];
        console.log('newOption: ', newOption);

        setFormState(prevState => ({
            ...prevState,
            newlyCreatedBrands: [...prevState.newlyCreatedBrands, newOption]
        }));
        
        // Optionally, you can add the new option to your state's brands list if needed
        const newBrandsList = [...formState.brandsList, newOption];
        console.log('newBrandsList: ', newBrandsList);
        setFormState(prevState => ({
            ...prevState,
            brandsList: newBrandsList
        }));

        // Call the existing handleBrandsChange with the updated list including the new option
        handleBrandsChange(newValue);
    };

    const handleUserPermissionsChange = event => {
        const values = Array.from(event.target.selectedOptions, option => option.value);
        setFormState(prevState => ({
            ...prevState,
            userPermissions: values
        }));
    };

    // 5th Section Discount Code Handler Function
    const handleSelectionAvailable = event => {
        const selectedOptions = Array.from(event.target.selectedOptions, option => {
            return { value: +option.value, label: option.label };
        });
        setSelectedAvailable(selectedOptions);
    };

    const handleSelectionSelected = event => {
        const selectedOptions = Array.from(event.target.selectedOptions, option => {
            return { value: +option.value, label: option.label };
        });
        setSelectedSelected(selectedOptions);
    };

    const addToSelected = () => {
        const uniqueSelected = selectedAvailable.filter(sa => !selectedCodes.some(sc => sc.value === sa.value));
        setSelectedCodes([...selectedCodes, ...uniqueSelected]);
        const newAvailableCodes = availableCodes.filter(code => !selectedAvailable.some(selected => selected.value === code.value));
        setAvailableCodes(newAvailableCodes);
        setSelectedAvailable([]);
    };

    const removeFromSelected = () => {
        const removedCodes = selectedSelected;
        const newSelectedCodes = selectedCodes.filter(code => !selectedSelected.some(selected => selected.value === code.value));
        setSelectedCodes(newSelectedCodes);
        // Add the removed codes back to the available discount codes
        const newAvailableCodes = [...availableCodes, ...removedCodes];
        setAvailableCodes(newAvailableCodes);
        setSelectedSelected([]);
    };

    const addAllToSelected = () => {
        setSelectedCodes([...selectedCodes, ...availableCodes]);
        setAvailableCodes([]);
    };

    const removeAllFromSelected = () => {
        setAvailableCodes([...availableCodes, ...selectedCodes]);
        setSelectedCodes([]);
    };

    const debouncedSearchAvailableCode = useDebouncedSearch(searchAvaliableCode);
    const debouncedSearchSelectedCode = useDebouncedSearch(searchSelectedCode);

    const getFilteredAvaliableCodes = () => {
        if (!debouncedSearchAvailableCode) {
            return availableCodes;
        }
        return availableCodes.filter(code => code.label.toLowerCase().includes(debouncedSearchAvailableCode.toLowerCase()));
    };

    const getFilteredSelectedCodes = () => {
        if (!debouncedSearchSelectedCode) {
            return selectedCodes;
        }
        return selectedCodes.filter(code => code.label.toLowerCase().includes(debouncedSearchSelectedCode.toLowerCase()));
    };

    // Memoized filtered code lists
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filteredAvailableCodes = useMemo(() => getFilteredAvaliableCodes(), [debouncedSearchAvailableCode, availableCodes]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filteredSelectedCodes = useMemo(() => getFilteredSelectedCodes(), [debouncedSearchSelectedCode, selectedCodes]);
    

    const handleBackClick = (e) => {
        e.preventDefault();
        history.push("/users");
    };

    const handleSubmitClick = e => {
        e.preventDefault();
        // formState?.selectedStates?.length !== 0 || formState?.selectedStates.length > 0 ? setStateIsValidPermission(true) : setStateIsValidPermission(false)
        // formState.selectedBrands?.length !== 0 || formState?.selectedBrands.length > 0 ? setBrandIsValid(true) : setBrandIsValid(false)
        // formState?.userType !== "" ? setUserTypeValid(true) : setUserTypeValid(false)

        // Validation on First Name and Email
        if (!formData.firstName || !formData.email) {
            let errorMsg = !formData.email ? "Email is required!" : "First Name is required!";
            addToast(errorMsg, {
                appearance: "error",
                autoDismiss: true
            });
            return;
        }

        // Check if any row has an empty title or URL
        const hasEmptyField = documentRows.some(row => (row.title && row.title.trim() !== "" && !row.url) || (row.url && row.url.trim() !== "" && !row.title));

        if (hasEmptyField) {
            // If any row has an empty title or URL, show a warning
            addToast("Please fill in the title and URL for all documents.", { appearance: "warning", autoDismiss: true });
            return; // Stop execution
        }

        const imageFormData = new FormData();
        imageFormData.append("image", imageUrl);

        const payload = {
            email: formData.email,
            first_name: formData.firstName,
            last_name: formData.lastName,
            telephone: formData.telephone,
            company: formData.company,
            contact_type: contactMethod,
            role: formState.userType,
            states: formState.selectedStates.some(item => item.value === "__all__")
                ? "__all__"
                : formState.selectedStates.some(item => item.value === "none")
                ? "none"
                : formState.selectedStates.map(brand => brand.value),
            brands: formState.selectedBrands.some(item => item.value === "__all__")
                ? "__all__"
                : formState.selectedBrands.some(item => item.value === "no")
                ? "no"
                : formState.selectedBrands
                .filter(item => !item.__isNew__) // Assuming __isNew__ is used to identify new brands
                .map(item => item.value),
            user_permissions: formState.userPermissions,
            discount_feature: toggleStates.discountCodePage === "on" ? 1 : 0,
            show_barcart_button: toggleStates.barcartButton === "on" ? 1 : 0,
            product_feature: toggleStates.productPage === "on" ? 1 : 0,
            weekly_report: toggleStates.weeklyEmail === "on" ? 1 : 0,
            insights: toggleStates.flaviarInsights === "on" ? true : false,
            insights_profile: insightsProfile,
            documents: documentRows.filter(({ title, url }) => title.trim() !== "" || url.trim() !== "").map(({ title, url }) => ({ document_title: title, document_url: url })),
            discount_codes: selectedCodes.map(code => code.value),
            daily_report: toggleStates.dailyEmail === "on" ? true : false,
            sonic_export: toggleStates.sonicExport === "on" ? true : false,
            retailer_billing: toggleStates.retailerBilling === "on" ? true : false,
            inventory_locations: formState.selectedRetailerInventory.map(item => item.value),
            newly_created_brands: formState.newlyCreatedBrands.length > 0 ? formState.newlyCreatedBrands.map(brand => brand.value) : [],
            fulfillment_states: formState.selectedFulfillmentStates.some(item => item.value === "__all__")
                ? "__all__"
                : formState.selectedFulfillmentStates.some(item => item.value === "none")
                ? "none"
                : formState.selectedFulfillmentStates.map(item => item.value),

            toggle_fulfillment_states:!fulfillmentStatesRole.includes(formState?.userType)? []:formState.fulfillmentStates,
            all_fullfillment_Active:!fulfillmentStatesRole.includes(formState?.userType)?false:formState.all_fullfillment_Active,
            all_tax_Active:!fulfillmentStatesRole.includes(formState?.userType)?false:formState.all_tax_Active,
            
        };

        console.log("payload: ", payload);
        const payloadString = JSON.stringify(payload);
        imageFormData.append("payload", payloadString);

        setSubmitBtnLoading(true);
        api.post("react/users/", imageFormData)
            .then(response => {
                setSubmitBtnLoading(false);
                if (response.data.success) {
                    addToast(response.data.success, { appearance: "success", autoDismiss: true });
                    // Redirect to the listing page when we successfully create user
                    history.push("/users");
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    let message = error?.response?.data?.error;
                    setSubmitBtnLoading(false);
                    addToast(message, { appearance: "error", autoDismiss: true });
                } else {
                    // Handle other errors (network errors, etc.)
                    console.log("error: ", error);
                }
            });
    };

    const handleImageChange = e => {
        const file = e.target.files[0];
        if (file && !["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
            setUnsupportedFile(true);
            return;
        }
        setUnsupportedFile(false);
        const reader = new FileReader();

        reader.onload = e => {
            setShowImg(e.target.result);
            setShowPreview(true);
        };

        if (file) {
            reader.readAsDataURL(file);
        }

        setImageUrl(file);
    };

    const handleRemoveImage = () => {
        setRemoveClicked(true);
        setShowImg(null);
        setImageUrl(null);
        setShowPreview(false);

        // Clear the file input field
        document.getElementById("image-upload").value = null;
    };


    const mapResponseToValuesAndLabels = (data) => ({
        value: data.id,
        label: data.name,
    });

    async function callApi(value) {
        let updatedBrands = [
            { value: "__all__", label: " All Brands" },
            { value: "no", label: "No Brands" }
        ];

        let data = await api.get(`brand/?q=${value}`)
          .then((response) => {
              console.log(response.data)
              return response.data
          })
          .then((response) => response?.results.map(mapResponseToValuesAndLabels))
          .then((final) =>
            final.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))
          );
        data = updatedBrands.concat(data)
        return data;
    }

    return (
        <Container fluid style={{ paddingTop: "7rem" }}>
            {/* First User Details Form ... */}
            <div className="user-form-container">
                <h2>Create User</h2>
                <form className="user-form">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div className="avatar-block mx-auto" style={{ width: "100%" }}>
                                <img
                                    src={showImg || "https://assets-barcart.s3.amazonaws.com/static/images/avatar.png"}
                                    alt="Preview"
                                    className="rounded-circle object-fit-cover"
                                    height="150"
                                    width="150"
                                />

                                {!showImg && removeClicked && (
                                    <div className="alert alert-info mt-2 mx-auto" style={{ width: "150px", paddingTop: "8px", paddingBottom: "8px" }}>
                                        No Picture
                                    </div>
                                )}
                                {unsupportedFile && (
                                    <div className="error-message" style={{ width: "100%" }}>
                                        Unsupported file format. Please select a JPEG, PNG, or JPG file.
                                    </div>
                                )}
                                <div className="btn-group my-2 btn-group-justified" style={{ display: "revert" }}>
                                    <label
                                        className="btn btn-info btn-sm"
                                        htmlFor="image-upload"
                                        data-toggle="tooltip"
                                        title="Browse"
                                        style={{ width: "30px", height: "28px" }}>
                                        <i className="fas fa-upload"></i>
                                        <input
                                            type="file"
                                            id="image-upload"
                                            ref={imageUploadRef}
                                            accept="image/jpeg, image/png, image/jpg"
                                            className="invisible"
                                            onChange={handleImageChange}
                                        />
                                    </label>
                                    {showPreview && (
                                        <label className="btn btn-danger btn-sm" data-toggle="tooltip" title="Remove" onClick={handleRemoveImage}>
                                            <i className="fas fa-trash"></i>
                                        </label>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <FormInput id="email" label="Email *" type="email" value={formData.email} onChange={handleChange} />
                    <FormInput id="firstName" label="First name *" type="text" value={formData.firstName} onChange={handleChange} />
                    <FormInput id="lastName" label="Last name" type="text" value={formData.lastName} onChange={handleChange} />
                    <FormInput id="telephone" label="Telephone" type="text" value={formData.telephone} onChange={handleChange} />
                    <FormInput id="company" label="Company" type="text" value={formData.company} onChange={handleChange} />
                    {formState.userType === "supplier" && (
                        <>
                            <div className="brand-input-group">
                                <label htmlFor="brands">Brands</label>
                                <div className="brands-multi-select-dropdown">
                                    <Select
                                        className="select-input"
                                        placeholder="Suppplier Brand... "
                                        value={formState.selectedSupplierBrands}
                                        onChange={handleBrandsChange}
                                        options={formState.supplierBrands}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        checked={true}
                                    />
                                </div>
                            </div>
                            {/*<div className="input-group-supplier">
                                <label htmlFor="facebookId">Facebook Business Manager ID</label>
                                <input type="text" id="facebookId" className="form-control disabled-input" placeholder="Facebook Business Manager ID" />
                            </div>
                            <div className="input-group-supplier">
                                <label htmlFor="googleAnalyticsId">Google Analytics ID</label>
                                <input type="text" id="googleAnalyticsId" className="form-control disabled-input" placeholder="Google Analytics ID" />
                            </div>*/}
                        </>
                    )}
                    {formState.userType === "retailer" &&<FormInput id="marketingFee" label="MArketing Fee" type="number" disabled = {true} />}
                </form>
            </div>
            {/* Second Contact Form ... */}
            <div className="user-contact-form-container">
                <form className="user-form">
                    <h3>Best Way To Contact Me</h3>
                    <hr />
                    {contactOptions.map(option => (
                        <RadioButton
                            key={option.value}
                            label={option.label}
                            value={option.value}
                            checkedValue={contactMethod}
                            onChange={handleContactMethodChange}
                        />
                    ))}
                </form>
            </div>
            {/* Third Invite Form ... */}
            <div className="user-invite-barcart-form-container">
                <form className="user-form">
                    <h3>
                        <i class="fas fa-user-plus"></i> Invite to Flaviar Checkout
                    </h3>
                    <hr />
                    <div className="invite-barcart-links">
                        {inviteTypes.map(invite => (
                            <InviteLink key={invite.label} inviteType={invite.label} path={invite.path} copyInviteToClipboard={copyInviteToClipboard} />
                        ))}
                    </div>
                </form>
            </div>
            {/* Forth Permissions Form ... */}
            <div className="user-permission-barcart-form-container">
                <form className="user-form">
                    <h3>Permissions</h3>
                    <hr />
                    <div className="permission-barcart">
                        <div className="select-group">
                            <label htmlFor="email">User Type *</label>
                            <CustomDropdown
                                className="select-input"
                                placeholder="Select User Type..."
                                options={formState.userTypeList}
                                value={formState.userType}
                                onChange={handleUserTypeChange}
                                isValid={userTypeValid}
                            />
                        </div>

                        {(formState?.userType === "retailer" || formState?.userType === "wms-retailer") && (
                            <>
                                <div className="select-group">
                                    <label htmlFor="email">Retailer Inventory</label>
                                    <Select
                                        className="select-input"
                                        placeholder="Select Retailer Inventory..."
                                        isMulti
                                        value={formState.selectedRetailerInventory}
                                        onChange={handleRetailerInventoryChange}
                                        options={formState.retailerInventoryLocations}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                    />
                                </div>
                                <div className="product-btn-form-container">
                                    <form className="sonic-export-retailer-btn">
                                        <div>
                                            <h3>Sonic Export</h3>
                                        </div>
                                        <label className="switch">
                                            <input type="checkbox" onChange={handleToggleChange("sonicExport")} checked={toggleStates.sonicExport === "on"} />
                                            <span className="slider round">
                                                <span className="toggle-text on-text">On</span>
                                                <span className="toggle-text off-text">Off</span>
                                            </span>
                                        </label>
                                    </form>
                                </div>
                                <div className="product-btn-form-container">
                                    <form className="retailer-billing-btn">
                                        <div>
                                            <h3>Retailer Billing</h3>
                                        </div>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                onChange={handleToggleChange("retailerBilling")}
                                                checked={toggleStates.retailerBilling === "on"}
                                            />
                                            <span className="slider round">
                                                <span className="toggle-text on-text">On</span>
                                                <span className="toggle-text off-text">Off</span>
                                            </span>
                                        </label>
                                    </form>
                                </div>
                                {/* <div className="select-group disable-cursor">
                                    <label htmlFor="firstName">Fulfillment States</label>
                                    <Select
                                        className="select-input disable"
                                        placeholder="Select Fulfillment States..."
                                        isMulti
                                        value={formState.selectedFulfillmentStates}
                                        onChange={handleFulfillmentStatesChange}
                                        options={stateListOptions}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        checked={true}
                                        isDisabled={true} //Disable the component
                                    />
                                </div> */}
                            </>
                        )}
                        {formState?.userType === "wms-retailer" && (
                            <>
                                {/* <div className="select-group disable-cursor">
                                    <label htmlFor="firstName">Fulfillment States</label>
                                    <Select
                                        className="select-input"
                                        placeholder="Select Fulfillment States..."
                                        isMulti
                                        value={formState.selectedFulfillmentStates}
                                        onChange={handleFulfillmentStatesChange}
                                        options={stateListOptions}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        checked={true}
                                        isDisabled={true} //Disable the component
                                    />
                                </div> */}
                            </>
                        )}

                        {(fulfillmentStatesRole.includes(formState?.userType)) && <div style={{ marginBottom: "1rem" }}>
                            <FulfillmentStatesTable formState={formState} setFormState={setFormState} />
                        </div>}
                        <div className="select-group">
                            <label htmlFor="firstName">States</label>
                            <Select
                                className="select-input"
                                placeholder="Select States..."
                                isMulti
                                value={formState.selectedStates}
                                onChange={handleStatesChange}
                                options={stateListOptions}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                allowSelectAll={true}
                                checked={true}
                            />
                        </div>
                        <div className="select-group">
                            <label htmlFor="lastName">Brands</label>
                            {/*<Select
                                className="select-input"
                                placeholder="Select Brands... "
                                isMulti
                                value={formState.selectedBrands}
                                onChange={handleBrandsChange}
                                options={formState.brandsList}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                allowSelectAll={true}
                                checked={true}
                            />*/}
                            {/*<CreatableSelect*/}
                            {/*    className="select-input"*/}
                            {/*    placeholder="Select Brands... "*/}
                            {/*    isMulti*/}
                            {/*    onChange={handleCreatableBrandsChange}*/}
                            {/*    options={formState.brandsList}*/}
                            {/*    closeMenuOnSelect={false}*/}
                            {/*    hideSelectedOptions={false}*/}
                            {/*    value={formState.selectedBrands}*/}
                            {/*    allowSelectAll={true}*/}
                            {/*/>*/}

                            <AsyncCreatableSelect
                            cacheOptions
                            placeholder="Select Brands... "
                            isMulti
                            loadOptions={callApi}
                            value={formState.selectedBrands}
                            onChange={handleCreatableBrandsChange}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            allowSelectAll={true}
                            defaultOptions
                            />
                        </div>
                        <div className="select-group">
                            <label htmlFor="lastName">User Permissions</label>
                            <Input
                                id="exampleSelectMulti"
                                className="select-input"
                                multiple
                                name="selectMulti"
                                type="select"
                                value={formState.userPermissions}
                                onChange={handleUserPermissionsChange}>
                                <option value="40">order | Order | Can view Order</option>
                                <option value="32">product | brand | Can view brand</option>
                            </Input>
                        </div>
                    </div>
                </form>
            </div>
            {/* Fifth Discount Codes Form is remaining... */}
            <div className="user-discount-code-form-container">
                <div className="user-form-discount-code">
                    <form className="user-form-discount-code-btn">
                        <div>
                            <h3>Discount Codes</h3>
                            <p>Enables the discount code page for the user</p>
                        </div>
                        <label className="switch">
                            <input type="checkbox" onChange={handleToggleChange("discountCodePage")} checked={toggleStates.discountCodePage === "on"} />
                            <span className="slider round">
                                <span className="toggle-text on-text">On</span>
                                <span className="toggle-text off-text">Off</span>
                            </span>
                        </label>
                    </form>
                    {toggleStates.discountCodePage === "on" && (
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <div style={{ width: "40%" }}>
                                <h3>Available Discount Codes</h3>
                                <input
                                    type="text"
                                    className="discount-code-search-box"
                                    placeholder="Search"
                                    value={searchAvaliableCode}
                                    onChange={e => setSearchAvailableCodes(e.target.value)}
                                />
                                <select multiple className="discount-code-select-box" onChange={handleSelectionAvailable}>
                                    {filteredAvailableCodes?.map(code => (
                                        <option key={code.value} value={code.value}>
                                            {code.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                                {/* 7278215305 - increase font size */}
                                <button className="button-font-size discount-code-add-btns" onClick={addToSelected} style={{ marginTop: "1.3rem" }}>
                                    Add <i class="fas fa-arrow-circle-right" />
                                </button>
                                {/* 7278215305 - increase font size */}
                                <button className="button-font-size discount-code-add-btns" onClick={addAllToSelected}>
                                    Add all <i class="fas fa-angle-double-right" style={{ color: "#fff" }} />
                                </button>
                                <hr style={{ margin: 0 }} />
                                {/* 7278215305 - increase font size */}
                                <button className="button-font-size discount-code-remove-btns" onClick={removeFromSelected}>
                                    <i class="fas fa-arrow-circle-left" style={{ color: "#fff" }} /> Remove
                                </button>
                                {/* 7278215305 - increase font size */}
                                <button className="button-font-size discount-code-remove-btns" onClick={removeAllFromSelected}>
                                    <i class="fas fa-angle-double-left" style={{ color: "#fff" }} /> Remove all
                                </button>
                            </div>
                            <div style={{ width: "40%" }}>
                                <h3>Selected Discount Codes</h3>
                                <input
                                    type="text"
                                    className="discount-code-search-box"
                                    placeholder="Search"
                                    value={searchSelectedCode}
                                    onChange={e => setSearchSelectedCode(e.target.value)}
                                />
                                <select multiple className="discount-code-select-box" onChange={handleSelectionSelected}>
                                    {filteredSelectedCodes
                                        ?.filter(code => code != null)
                                        .map(code => (
                                            <option key={code.value} value={code.value}>
                                                {code.label}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Sixth Documents Form ... */}
            <div className="user-documents-barcart-form-container">
                <form className="user-form">
                    <h3>Documents</h3>
                    <hr />
                    {documentRows.map((row, index) => (
                        <div className="documents-barcart" key={row.id}>
                            <div className="input-group">
                                <label htmlFor={`title-${row.id}`}>Title</label>
                                <input
                                    type="text"
                                    id={`title-${row.id}`}
                                    placeholder="Title"
                                    value={row.title}
                                    onChange={value => handleTitleChange(value, row.id)}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor={`url-${row.id}`}>URL</label>
                                <input type="text" id={`url-${row.id}`} placeholder="URL" value={row.url} onChange={value => handleUrlChange(value, row.id)} />
                            </div>
                            {(index !== 0 || documentRows.length > 1) && (
                                <div className="document-delete-btn">
                                    <button className="btn btn-sm btn-danger" onClick={() => handleDeleteDocumentBtn(row.id)}>
                                        <FaTrash />
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                    <div className="text-center">
                        <button type="button" onClick={handleAddAnother} className="add-another-btn">
                            Add another
                        </button>
                    </div>
                </form>
            </div>
            {/* Seventh Product Form ... */}
            <div className="product-btn-form-container">
                <form className="user-form-product-btn">
                    <div>
                        <h3>Product</h3>
                        <p>Enables the product page for the user</p>
                    </div>
                    <label className="switch">
                        <input type="checkbox" onChange={handleToggleChange("productPage")} checked={toggleStates.productPage === "on"} />
                        <span className="slider round">
                            <span className="toggle-text on-text">On</span>
                            <span className="toggle-text off-text">Off</span>
                        </span>
                    </label>
                </form>
            </div>
            {/* Eighth Weekly Email Report Form ... */}
            {formState.userType === "supplier" && (
                <div className="weekly-email-report-btn-form-container">
                    <form className="user-form-weekly-email-btn">
                        <div>
                            <h3>Weekly Email Report</h3>
                        </div>
                        <label className="switch">
                            <input type="checkbox" onChange={handleToggleChange("weeklyEmail")} checked={toggleStates.weeklyEmail === "on"} />
                            <span className="slider round">
                                <span className="toggle-text on-text">On</span>
                                <span className="toggle-text off-text">Off</span>
                            </span>
                        </label>
                    </form>
                </div>
            )}
            
            {/* Nineth Daily Email Report Form ... */}
            <div className="weekly-email-report-btn-form-container">
                <form className="user-form-weekly-email-btn">
                    <div>
                        <h3>Daily Email Report</h3>
                    </div>
                    <label className="switch">
                        <input type="checkbox" onChange={handleToggleChange("dailyEmail")} checked={toggleStates.dailyEmail === "on"} />
                        <span className="slider round">
                            <span className="toggle-text on-text">On</span>
                            <span className="toggle-text off-text">Off</span>
                        </span>
                    </label>
                </form>
            </div>
            {/* Tenth Show Barcart Btn Form ... */}
            <div className="show-barcart-btn-form-container">
                <form className="user-form-show-barcart-btn">
                    <h3>Show Flaviar Checkout Button</h3>
                    <label className="switch">
                        <input type="checkbox" onChange={handleToggleChange("barcartButton")} checked={toggleStates.barcartButton === "on"} />
                        <span className="slider round">
                            <span className="toggle-text on-text">On</span>
                            <span className="toggle-text off-text">Off</span>
                        </span>
                    </label>
                </form>
            </div>
            {/* Ship Station Intergration Btn Form ... */}
            {/*{formState?.userType === "retailer" && (
                <div className="ship-station-integration-btn-form-container">
                    <form className="user-form-ship-station-integration-btn">
                        <h3>ShipStation Integration</h3>
                        <label className="switch">
                            <input type="checkbox" onChange={handleToggleChange("shipStationIntegration")} checked={toggleStates.shipStationIntegration === "on"} />
                            <span className="slider round">
                                <span className="toggle-text on-text">On</span>
                                <span className="toggle-text off-text">Off</span>
                            </span>
                        </label>
                    </form>
                </div>
            )}*/}
            {/** 6893788815 - Integrate Insights with Barcart - UI field to edit user's JSON data */}
            {formState.userType === "supplier" ? (
            <div className="insights-btn-form-container">
                <form className="user-form-insights-btn">
                    <h3>Insights</h3>
                    <div className="insight-label">Enable Flaviar Insights</div>
                    <div className="insight-toggle">
                        <label className="switch">
                            <input type="checkbox" onChange={handleToggleChange("flaviarInsights")} checked={toggleStates.flaviarInsights === "on"} />
                            <span className="slider round">
                                <span className="toggle-text on-text">On</span>
                                <span className="toggle-text off-text">Off</span>
                            </span>
                        </label>
                    </div>
                    <div className="insight-input">Flaviar Insights JSON</div>
                    <div className="insight-json">
                        <Input className="insights-textarea" type="textarea" value={insightsProfile} onChange={handleInsightsProfile}/>
                    </div>
                </form>
            </div>
        ) : (
            <></>
        )}
            <div className="submit-back-btn-at-bottom">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <button className="back-btn" onClick={handleBackClick}>
                        ← Back
                    </button>
                    <button className="submit-btn" onClick={handleSubmitClick} disabled={submitBtnLoading}>
                        Submit → {submitBtnLoading && <Spinner size="sm" color="light" />}
                    </button>
                </div>
            </div>
        </Container>
    );
};
export default CreateUser;